import React from 'react';
import { useLocation } from 'react-router-dom';
import PDFViewer from '../../components/PDFViewer';
 // Adjust the import path based on your project structure

const PDFPage = () => {
  const location = useLocation();
  const base64Data =   location?.state?.makbuzBase64;; // Retrieve the base64 data from the URL parameter.
  const policeNo = location?.state?.policeNo;
  const makbuzTarih = location?.state?.makbuzTarih;

  return (
    <div style={{ height: '100%' }}>
  <PDFViewer base64Data={base64Data} policeNo={policeNo} makbuzTarih={makbuzTarih} />
</div>
  );
};

export default PDFPage;

