/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCaretUp({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.634 15.286a1.257 1.257 0 000-1.774l-3.75-3.763a1.247 1.247 0 00-1.768 0l-3.75 3.763a1.257 1.257 0 000 1.774c.488.49 8.78.49 9.268 0z"
        fill={primaryColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconCaretUp;
