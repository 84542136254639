/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconHomeSelected({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={secondaryColor}
          d="M11.33 3.58l-5.5 2.76c-.51.254-.83.775-.83 1.345v10.726a3.005 3.005 0 003 3.01h8c1.657 0 3-1.348 3-3.01V7.685c0-.57-.32-1.09-.83-1.346l-5.5-2.76a1.502 1.502 0 00-1.341 0z"
        />
        <path
          fill={primaryColor}
          d="M10 21.422V17.41c0-1.109.896-2.007 2-2.007 1.105 0 2 .898 2 2.007v4.013h-4zM4.447 9.276L12 5.486l7.553 3.79a.998.998 0 001.342-.45 1.005 1.005 0 00-.448-1.345l-8-4.014a1.004 1.004 0 00-.894 0l-8 4.014c-.494.248-.694.85-.447 1.346a.997.997 0 001.34.449z"
        />
      </g>
    </svg>
  );
}

export default SvgIconHomeSelected;
