/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconSingleUser({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"width={size} height={size} {...props}>
      <rect width={size} height={size} rx="22" fill={primaryColor}/>
      <path fill={secondaryColor} fill-rule="evenodd" clip-rule="evenodd" d="M12.833 16.417C12.833 18.8482 13.7988 21.1799 15.518 22.899C17.2371 24.6182 19.5688 25.584 22 25.584C24.4312 25.584 26.7629 24.6182 28.4821 22.899C30.2012 21.1799 31.167 18.8482 31.167 16.417C31.167 13.9858 30.2012 11.6541 28.4821 9.93495C26.7629 8.21581 24.4312 7.25 22 7.25C19.5688 7.25 17.2371 8.21581 15.518 9.93495C13.7988 11.6541 12.833 13.9858 12.833 16.417Z"/>
      <path fill={secondaryColor} d="M16.5 29.251H27.5C29.9311 29.251 32.2626 30.2167 33.9817 31.9356C35.7008 33.6545 36.6667 35.9859 36.667 38.417V44H7.33301V38.417C7.33327 35.9859 8.2992 33.6545 10.0183 31.9356C11.7374 30.2167 14.0689 29.251 16.5 29.251Z"/>
    </svg>
  );
}

export default SvgIconSingleUser;
