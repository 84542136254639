// import React from 'react';


// const PDFViewer = ({ base64Data }) => {

//   const data = `data:application/pdf;base64,${base64Data}`
//   return (
//     // <div style={{height:"100%"}}>
//     //    <iframe title="PDF Viewer" src={data} width="100%" height="100%"></iframe>
//     // </div>

//   );
// };

// export default PDFViewer;
import React from 'react';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { AFButton } from '@aegon/aegon-faces-ui-components';
import Icon from 'components/icons/Icon';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function PDFViewer({ base64Data, policeNo, makbuzTarih }) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${base64Data}`;
        const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
        const date = new Date(makbuzTarih);
        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();
        link.download = policeNo+'/'+monthName+'-'+year;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="pdf-viewer-container">
          <AFButton size="small" onClick={handleDownload} style={{marginLeft: '10px'}}>
            <Icon
              name="file-download"
              primaryColor="black"
              size="25"
            />
            <span style={{marginLeft: '10px'}}>Makbuz İndir</span>
          </AFButton>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <div className="pdf-view-wrapper">
                    <Viewer fileUrl={`data:application/pdf;base64,${base64Data}`} plugins={[defaultLayoutPluginInstance]} />
                </div>
            </Worker>
        </div>
    );
}

