/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconWallet({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M2.327 6.008a3.005 3.005 0 011.311-1.315c.642-.328 1.482-.328 3.162-.328h10.4c1.68 0 2.52 0 3.162.328.564.289 1.023.749 1.311 1.315C22 6.652 22 7.495 22 9.181v6.422c0 1.686 0 2.528-.327 3.172a3.005 3.005 0 01-1.311 1.316c-.642.328-1.482.328-3.162.328H6.8c-1.68 0-2.52 0-3.162-.328a3.005 3.005 0 01-1.311-1.316C2 18.131 2 17.29 2 15.603V9.18c0-1.686 0-2.529.327-3.173z"
          fill={secondaryColor}
        />
        <path
          d="M22 9.382v6.02h-5c-1.657 0-3-1.348-3-3.01a3.005 3.005 0 013-3.01h5zm-4 3.01c0-.554-.448-1.003-1-1.003s-1 .449-1 1.003c0 .554.448 1.003 1 1.003s1-.449 1-1.003z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconWallet;
