/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconProfileAgency({ className }) {
  return (
    // <svg
    //   className={className}
    //   width="61"
    //   height="61"
    //   viewBox="0 0 61 83"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M22.9979 4.0026C23.0005 4.0011 23.003 3.9996 23.0055 3.9982C23.0018 3.9979 22.9982 3.9977 22.9946 3.9974C22.9957 3.9991 22.9968 4.0008 22.9979 4.0026ZM67.2648 47.961C66.4863 50.3646 65.2214 52.4428 63.6305 54.2374C63.4854 54.5651 63.2446 54.8127 62.9516 54.9718C59.9123 58.071 55.8494 60.2349 51.6022 61.6143C37.0173 66.3521 17.7303 64.5898 8.48038 51.2591C8.29653 50.9932 8.21705 50.7316 8.2046 50.4826C7.27913 48.8452 6.66917 46.9917 6.42117 45.1418C5.76955 40.2954 6.94351 35.1241 4.82014 30.5295C3.27466 27.1865 0.842482 24.5358 0.21002 20.803C-0.827006 14.6843 2.07006 8.29745 6.83291 4.34785C12.2464 -0.140976 19.7943 -0.963848 26.4565 1.00814C34.0651 3.26051 39.1526 8.62838 43.5626 14.8341C45.8497 18.053 48.0262 21.389 50.9228 24.1314C53.8879 26.9399 57.6089 28.7139 60.9086 31.0875C66.5673 35.1574 69.4591 41.1838 67.2648 47.961V47.961Z"
    //     fill="#55B186"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M50.7268 38.1514C48.2646 37.6354 43.0323 36.3702 41.8487 33.4016C40.6651 30.4331 40.6651 24.4959 40.6651 24.4959H28.8278C28.8278 24.4959 28.8278 30.4331 27.6442 33.4016C26.4606 36.3702 21.2288 37.6354 18.7666 38.1514C14.0508 39.1403 15.0869 41.8119 17.3014 44.2365C20.0268 47.2206 23.9337 48.8384 27.968 48.8384H41.5249C45.5593 48.8384 49.4656 47.2206 52.1915 44.2365C54.406 41.8119 55.4416 39.1403 50.7268 38.1514"
    //     fill="#FFFFFE"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M50.7268 38.1514C48.2646 37.6354 43.0323 36.3702 41.8487 33.4016C40.6651 30.4331 40.6651 24.4959 40.6651 24.4959H28.8278C28.8278 24.4959 28.8278 30.4331 27.6442 33.4016C26.4606 36.3702 21.2288 37.6354 18.7666 38.1514C14.0508 39.1403 15.0869 41.8119 17.3014 44.2365C20.0268 47.2206 23.9337 48.8384 27.968 48.8384H41.5249C45.5593 48.8384 49.4656 47.2206 52.1915 44.2365C54.406 41.8119 55.4416 39.1403 50.7268 38.1514Z"
    //     stroke="#1B1A1C"
    //     strokeWidth="0.5"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M41.2854 31.4075C40.5469 28.294 40.6646 24.4959 40.6646 24.4959H28.8272C28.8272 24.4959 28.8272 30.4331 27.6437 33.4016C27.2793 34.3157 26.53 35.0679 25.5967 35.6866C25.6198 35.6948 25.6434 35.701 25.6666 35.7093C33.2825 36.563 36.6431 35.3504 41.2854 31.4075"
    //     fill="#9DA19E"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M50.7268 38.1514C48.2646 37.6354 43.0323 36.3702 41.8487 33.4016C40.6651 30.4331 40.6651 24.4959 40.6651 24.4959H28.8278C28.8278 24.4959 28.8278 30.4331 27.6442 33.4016C26.4606 36.3702 21.2288 37.6354 18.7666 38.1514C14.0508 39.1403 15.0869 41.8119 17.3014 44.2365C20.0268 47.2206 23.9337 48.8384 27.968 48.8384H41.5249C45.5593 48.8384 49.4656 47.2206 52.1915 44.2365C54.406 41.8119 55.4416 39.1403 50.7268 38.1514Z"
    //     stroke="#1B1A1C"
    //     strokeWidth="0.5"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M54.6196 39.3756C53.4375 38.8203 52.1409 38.3658 50.7265 38.0652C49.3554 37.7734 47.126 37.2457 45.2141 36.316C36.2949 41.4563 28.1522 38.6603 23.9873 36.4535C22.1444 37.2986 20.0681 37.788 18.7663 38.0652C15.5979 38.7392 13.0221 40.183 10.9983 41.8046C7.55238 44.5651 5.23148 48.5258 4.33262 52.8938L3 82.316H66L65.1083 53.641C64.1 47.4334 60.2337 42.0132 54.6196 39.3756Z"
    //     fill="black"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M45.9258 14.7086C45.9258 14.0234 45.474 13.3424 44.5685 13.1227C44.1656 6.46329 41.3898 1.31598 34.717 1.31598C28.0667 1.31598 25.2874 6.42926 24.8706 13.0557C23.6464 13.1222 23.041 13.9131 23.041 14.7086C23.041 15.4592 23.8659 16.4811 24.8778 16.4228C25.4549 23.1106 29.756 27.8109 34.717 27.8109C40.3527 27.8109 44.09 23.0905 44.571 16.3785C45.3502 16.1583 45.9258 15.336 45.9258 14.7086"
    //     fill="#FFFFFE"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M45.9258 14.7086C45.9258 14.0234 45.474 13.3424 44.5685 13.1227C44.1656 6.46329 41.3898 1.31598 34.717 1.31598C28.0667 1.31598 25.2874 6.42926 24.8706 13.0557C23.6464 13.1222 23.041 13.9131 23.041 14.7086C23.041 15.4592 23.8659 16.4811 24.8778 16.4228C25.4549 23.1106 29.756 27.8109 34.717 27.8109C40.3527 27.8109 44.09 23.0905 44.571 16.3785C45.3502 16.1583 45.9258 15.336 45.9258 14.7086Z"
    //     stroke="#1B1A1C"
    //     strokeWidth="0.5"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M44.4258 15.3247C44.4243 15.1422 44.4618 14.9669 44.5178 14.7942C44.578 14.622 44.6458 14.4492 44.8344 14.3059C44.9249 14.2332 45.1217 14.2219 45.2137 14.3137C45.264 14.3503 45.2897 14.3951 45.3262 14.4364C45.374 14.5054 45.373 14.5642 45.3745 14.6147C45.372 14.8137 45.3206 14.9818 45.2404 15.1463C45.2162 14.9689 45.2147 14.7875 45.1798 14.639C45.169 14.6044 45.1541 14.573 45.1484 14.5725C45.1222 14.5539 45.0986 14.5225 45.0759 14.5132C45.0302 14.4848 45.0215 14.4951 44.9737 14.5214C44.8765 14.5833 44.784 14.7385 44.6956 14.8766C44.6078 15.0205 44.525 15.1731 44.4258 15.3247"
    //     fill="#0A090B"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M24.54 15.3247C24.4409 15.1731 24.3581 15.0205 24.2702 14.8766C24.1813 14.7385 24.0888 14.5833 23.9922 14.5214C23.9444 14.4951 23.9357 14.4848 23.8894 14.5132C23.8668 14.5225 23.8432 14.5539 23.8175 14.5725C23.8113 14.573 23.7964 14.6044 23.7861 14.639C23.7512 14.7875 23.7491 14.9689 23.725 15.1463C23.6453 14.9818 23.5934 14.8137 23.5908 14.6147C23.5929 14.5642 23.5913 14.5054 23.6391 14.4364C23.6756 14.3951 23.7013 14.3503 23.7517 14.3137C23.8442 14.2219 24.0405 14.2332 24.1315 14.3059C24.3196 14.4492 24.3879 14.622 24.4481 14.7942C24.5041 14.9669 24.5411 15.1422 24.54 15.3247"
    //     fill="#0A090B"
    //   />
    //   <path d="M18.4855 51.7956V59" stroke="#1B1A1C" strokeWidth="0.5" />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M28.3507 17.8643C28.3507 17.9679 28.267 18.0514 28.1642 18.0514C28.0614 18.0514 27.9776 17.9679 27.9776 17.8643C27.9776 17.7612 28.0614 17.6771 28.1642 17.6771C28.267 17.6771 28.3507 17.7612 28.3507 17.8643"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M29.925 17.1626C29.925 17.2657 29.8412 17.3498 29.7384 17.3498C29.6351 17.3498 29.5519 17.2657 29.5519 17.1626C29.5519 17.059 29.6351 16.9755 29.7384 16.9755C29.8412 16.9755 29.925 17.059 29.925 17.1626"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M29.925 18.484C29.925 18.5871 29.8412 18.6711 29.7384 18.6711C29.6351 18.6711 29.5519 18.5871 29.5519 18.484C29.5519 18.3803 29.6351 18.2968 29.7384 18.2968C29.8412 18.2968 29.925 18.3803 29.925 18.484"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M31.1486 17.6772C31.1486 17.7808 31.0653 17.8643 30.962 17.8643C30.8587 17.8643 30.7755 17.7808 30.7755 17.6772C30.7755 17.574 30.8587 17.49 30.962 17.49C31.0653 17.49 31.1486 17.574 31.1486 17.6772"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M40.7569 17.8643C40.7569 17.9679 40.8402 18.0514 40.9435 18.0514C41.0463 18.0514 41.13 17.9679 41.13 17.8643C41.13 17.7612 41.0463 17.6771 40.9435 17.6771C40.8402 17.6771 40.7569 17.7612 40.7569 17.8643"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M39.1817 17.1626C39.1817 17.2657 39.265 17.3498 39.3683 17.3498C39.4711 17.3498 39.5548 17.2657 39.5548 17.1626C39.5548 17.059 39.4711 16.9755 39.3683 16.9755C39.265 16.9755 39.1817 17.059 39.1817 17.1626"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M39.1817 18.484C39.1817 18.5871 39.265 18.6711 39.3683 18.6711C39.4711 18.6711 39.5548 18.5871 39.5548 18.484C39.5548 18.3803 39.4711 18.2968 39.3683 18.2968C39.265 18.2968 39.1817 18.3803 39.1817 18.484"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M37.9581 17.6772C37.9581 17.7808 38.0414 17.8643 38.1447 17.8643C38.2474 17.8643 38.3312 17.7808 38.3312 17.6772C38.3312 17.574 38.2474 17.49 38.1447 17.49C38.0414 17.49 37.9581 17.574 37.9581 17.6772"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M32.4326 20.8762C32.7821 21.1288 33.1495 21.3319 33.5196 21.4984C33.8927 21.6572 34.2797 21.7629 34.6672 21.7681C35.0521 21.7794 35.4432 21.7057 35.8045 21.5294C35.9967 21.4644 36.1581 21.3345 36.3441 21.2391C36.523 21.1314 36.6797 20.9906 36.8678 20.8762C36.7486 21.054 36.616 21.2283 36.4726 21.3953C36.3071 21.5366 36.1591 21.7119 35.9566 21.814C35.5856 22.0722 35.1143 22.1893 34.6559 22.181C33.7236 22.1486 32.9039 21.5933 32.4326 20.8762"
    //     fill="#FFFFFE"
    //   />
    // </svg>
    <svg width="61" height="61" viewBox="0 0 603 603" fill="none" className={className}>

<path d="M276.267 78.6667C263.6 82 256.4 85.3334 245.333 92.6667C227.467 104.533 212.933 123.733 205.867 144.667C201.067 159.067 199.867 181.467 203.333 196.8C211.2 232.667 237.467 261.067 273.333 272.267C281.733 274.8 286.133 275.333 301.333 275.333C316.533 275.333 320.933 274.8 329.333 272.267C358 263.333 379.6 244.533 392.133 218C399.467 202.267 401.867 189.6 401.067 170.8C399.867 143.867 390.933 123.867 371.467 104.667C358.8 92.2667 349.867 86.5334 333.733 80.9334C323.2 77.3334 320 76.8 304 76.4C289.333 76.1334 284.133 76.5334 276.267 78.6667Z" fill="#E2000F"/>
<path d="M264.8 354C212.267 360.667 160.133 376.4 127.2 395.6C95.0667 414.533 78.6667 435.333 75.7333 460.933C73.8667 477.467 75.0667 507.6 77.7333 512.8C80.4 518 88.2667 524.933 93.0667 526.267C97.7333 527.6 504.933 527.6 509.6 526.267C514.4 524.933 522.267 518 524.933 512.8C527.6 507.6 528.8 477.467 526.933 460.933C525.867 451.733 524.533 446.8 520.533 438.933C502.133 401.2 438.133 369.733 352 355.867C330.133 352.267 285.467 351.333 264.8 354Z" fill="#E2000F"/>
</svg>
  );
}

export default SvgIconProfileAgency;
