/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconTalep({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.327 4.002a3.005 3.005 0 011.311-1.316c.642-.328 1.482-.328 3.162-.328h5.875c.489 0 .733 0 .963.056.205.049.4.13.579.24.201.124.374.297.72.645l2.126 2.132c.346.347.519.52.642.723.11.18.19.376.24.58.055.231.055.477.055.968v9.907c0 1.686 0 2.53-.327 3.173a3.005 3.005 0 01-1.311 1.316c-.642.328-1.482.328-3.162.328H8.8c-1.68 0-2.52 0-3.162-.328a3.005 3.005 0 01-1.311-1.316C4 20.138 4 19.295 4 17.61V7.174c0-1.686 0-2.528.327-3.172z"
          fill={secondaryColor}
        />
        <path
          d="M15.037 21.732a.5.5 0 00.654.656l2.126-.89a1 1 0 00.323-.216l3.563-3.575c.39-.392.39-1.027 0-1.419l-.586-.588a.998.998 0 00-1.414 0l-3.563 3.575a1.004 1.004 0 00-.216.324l-.887 2.133zM15.414 2.358c.375 0 .735.15 1 .416l3.172 3.182c.265.266.414.627.414 1.003 0 .784-.633 1.42-1.414 1.42H16c-1.105 0-2-.9-2-2.007V3.777c0-.784.633-1.419 1.414-1.419z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconTalep;
