/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCheckboxCheck({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.436 5.553a4.007 4.007 0 011.748-1.754C6.04 3.36 7.16 3.36 9.4 3.36h5.2c2.24 0 3.36 0 4.216.438a4.007 4.007 0 011.748 1.754C21 6.41 21 7.535 21 9.783v5.218c0 2.247 0 3.371-.436 4.23a4.007 4.007 0 01-1.748 1.754c-.856.437-1.976.437-4.216.437H9.4c-2.24 0-3.36 0-4.216-.437a4.007 4.007 0 01-1.748-1.754C3 18.372 3 17.248 3 15V9.783c0-2.248 0-3.372.436-4.23z"
          fill={primaryColor}
        />
        <path
          d="M15.707 11.095l-4 4.013a.998.998 0 01-1.414 0l-2-2.007a1.006 1.006 0 010-1.419.998.998 0 011.414 0L11 12.98l3.293-3.304a.998.998 0 011.414 0c.39.391.39 1.027 0 1.419z"
          fill={secondaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconCheckboxCheck;
