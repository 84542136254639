/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconSoru({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.436 5.553a4.007 4.007 0 011.748-1.754C6.04 3.36 7.16 3.36 9.4 3.36h5.2c2.24 0 3.36 0 4.216.438a4.007 4.007 0 011.748 1.754C21 6.41 21 7.535 21 9.783v5.218c0 2.247 0 3.371-.436 4.23a4.007 4.007 0 01-1.748 1.754c-.856.437-1.976.437-4.216.437H9.4c-2.24 0-3.36 0-4.216-.437a4.007 4.007 0 01-1.748-1.754C3 18.372 3 17.248 3 15V9.783c0-2.248 0-3.372.436-4.23z"
          fill={secondaryColor}
        />
        <path
          d="M9.767 10.25a1.005 1.005 0 01-.365-1.37A2.997 2.997 0 0115 10.384c0 1.102-.673 2-1.5 2.509-.664.407-.25 1.505-1.503 1.505a1.002 1.002 0 01-.994-1.108c.028-.53.263-.962.52-1.276.271-.332.628-.603.9-.765 1.517-.905-.333-2.756-1.29-1.366a.998.998 0 01-1.366.366zm1.23 6.155c0 .555.448 1.004 1 1.004.553 0 1-.45 1-1.004s-.447-1.003-1-1.003c-.552 0-1 .45-1 1.003z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconSoru;
