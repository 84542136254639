/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconUyari({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M1.75 10.743a8.75 8.75 0 1 0 17.5 0 8.75 8.75 0 0 0-17.5 0z" fill={primaryColor} />
        <path
          d="M11.375 14.243a.875.875 0 1 0-1.75 0 .875.875 0 0 0 1.75 0zm-.875-2.625a.875.875 0 0 0 .875-.875v-3.5a.875.875 0 0 0-1.75 0v3.5c0 .483.392.875.875.875z"
          fill={secondaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconUyari;
