/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconUser({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={primaryColor}
          d="M9 14.58h6c2.761 0 5 2.248 5 5.018a3.005 3.005 0 01-3 3.01H7c-1.657 0-3-1.347-3-3.01 0-2.77 2.239-5.016 5-5.016z"
        />
        <path
          fill={secondaryColor}
          d="M7 7.558c0 2.77 2.239 5.017 5 5.017s5-2.246 5-5.017c0-2.77-2.239-5.017-5-5.017S7 4.787 7 7.558z"
        />
      </g>
    </svg>
  );
}

export default SvgIconUser;
