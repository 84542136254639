/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconMailnew({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M2.327 5.638a3 3 0 0 1 1.311-1.311C4.28 4 5.12 4 6.8 4h10.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C22 6.28 22 7.12 22 8.8v6.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.72 20 18.88 20 17.2 20H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C2 17.72 2 16.88 2 15.2V8.8c0-1.68 0-2.52.327-3.162z"
          fill={secondaryColor}
        />
        <path
          d="m7.566 8.175 3.868 2.654a1 1 0 0 0 1.132 0l3.868-2.654a1 1 0 1 1 1.132 1.65l-3.869 2.653a3 3 0 0 1-3.394 0L6.434 9.825a1 1 0 0 1 1.132-1.65z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconMailnew;
