/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
 import '@aegon/aegon-faces-ui-components/public/style.css';
 import 'resources/scss/style.scss';

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
// import FontFaceObserver from 'fontfaceobserver';
import history from 'utils/history';
// import 'sanitize.css/sanitize.css';


// App Based Custom Styles
import { AFSpinner } from '@aegon/aegon-faces-ui-components';
// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./resources/img/Favicon.png';
import '!file-loader?name=[name].[ext]!./resources/img/Faviconresized.png';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import DynamicMetaDescription from './utils/DynamicMetaDescription';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Observe loading
// the index.html file and this observer
// const font = new FontFaceObserver('Titillium Web');

// Promise.all([font.load()]).then(() => {
//   document.body.classList.add('font-loaded');
// });

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

window.covid19 = true;

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div style={{display:'flex',alignItems:'center',justifyContent:'center', width:'100%', height:'100vh'}}><AFSpinner style={{width: '3rem', height: '3rem', color: 'var(--bs-red)' }} /></div>}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <DynamicMetaDescription />
          <App />
        </ConnectedRouter>
      </LanguageProvider>
      </Suspense>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/tr.js')])) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
