/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconInstagram({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.527 3.727h.238l.62.001c.865.004 1.297.011 1.731.028l.188.007.406.018a6.586 6.586 0 012.185.42 4.61 4.61 0 012.633 2.64c.261.702.403 1.443.418 2.193.039.852.051 1.19.054 2.955v1.535c-.003 1.765-.015 2.104-.054 2.956a6.648 6.648 0 01-.418 2.192 4.61 4.61 0 01-2.633 2.641c-.699.263-1.438.405-2.184.42-.85.039-1.187.051-2.946.054h-1.53a68.944 68.944 0 01-2.258-.025l-.186-.007a78.65 78.65 0 01-.501-.022 6.586 6.586 0 01-2.185-.42 4.61 4.61 0 01-2.632-2.641 6.648 6.648 0 01-.419-2.192 44.004 44.004 0 01-.052-2.334L3 13.524v-1.296-.239l.002-.622c.003-.744.009-1.168.02-1.549l.007-.188.025-.596c.015-.75.157-1.49.419-2.192A4.61 4.61 0 016.105 4.2a6.586 6.586 0 012.184-.42c.739-.033 1.09-.047 2.326-.052l.62-.001h.238zm.01 1.627h-1.074c-1.916.001-2.227.012-3.1.052a4.966 4.966 0 00-1.671.311 2.986 2.986 0 00-1.708 1.714 5.013 5.013 0 00-.31 1.677c-.038.838-.05 1.16-.052 2.868v1.562c.002 1.708.014 2.03.052 2.868.007.573.112 1.14.31 1.677.303.787.923 1.41 1.708 1.714.535.199 1.1.304 1.671.31.19.01.353.017.512.023l.19.007c.477.015.996.021 2.157.023h1.556c1.703-.003 2.024-.014 2.859-.052a4.966 4.966 0 001.671-.311 2.986 2.986 0 001.708-1.714 5.013 5.013 0 00.31-1.677c.01-.229.019-.419.026-.61l.006-.191c.012-.422.018-.905.02-1.843v-.466V12.478 11.75a57.961 57.961 0 00-.026-2.034l-.008-.192a89.57 89.57 0 00-.018-.417 5.013 5.013 0 00-.31-1.677 2.986 2.986 0 00-1.708-1.714 4.966 4.966 0 00-1.671-.31c-.873-.04-1.184-.052-3.1-.053zM12 8.119a4.63 4.63 0 014.622 4.638A4.63 4.63 0 0112 17.394a4.63 4.63 0 01-4.622-4.637A4.63 4.63 0 0112 8.119zm0 1.628c-1.657 0-3 1.347-3 3.01a3.005 3.005 0 003 3.01c.796 0 1.559-.317 2.121-.882.563-.564.879-1.33.879-2.128a3.005 3.005 0 00-3-3.01zm4.804-2.894c.597 0 1.08.485 1.08 1.083 0 .599-.483 1.084-1.08 1.084-.596 0-1.08-.485-1.08-1.084 0-.598.484-1.083 1.08-1.083z"
        fill="#212739"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconInstagram;
