/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCalculator({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.327 5.005A3.005 3.005 0 014.638 3.69C5.28 3.36 6.12 3.36 7.8 3.36h8.4c1.68 0 2.52 0 3.162.329.564.288 1.023.749 1.311 1.315C21 5.649 21 6.492 21 8.178v8.428c0 1.686 0 2.529-.327 3.173a3.005 3.005 0 01-1.311 1.315c-.642.328-1.482.328-3.162.328H7.8c-1.68 0-2.52 0-3.162-.328a3.005 3.005 0 01-1.311-1.315C3 19.135 3 18.292 3 16.606V8.178c0-1.686 0-2.529.327-3.173z"
          fill={secondaryColor}
        />
        <path
          d="M15 10.385V9.382h-1c-.552 0-1-.45-1-1.004s.448-1.003 1-1.003h1V6.372c0-.555.448-1.004 1-1.004s1 .45 1 1.004v1.003h1c.552 0 1 .45 1 1.003 0 .555-.448 1.004-1 1.004h-1v1.003c0 .554-.448 1.004-1 1.004s-1-.45-1-1.004zM5 8.378c0 .555.448 1.004 1 1.004h4c.552 0 1-.45 1-1.004s-.448-1.003-1-1.003H6c-.552 0-1 .45-1 1.003zm9 5.017h4c.552 0 1 .45 1 1.004s-.448 1.003-1 1.003h-4c-.552 0-1-.45-1-1.003 0-.555.448-1.004 1-1.004zm-1 5.017c0 .554.448 1.004 1 1.004h4c.552 0 1-.45 1-1.004s-.448-1.003-1-1.003h-4c-.552 0-1 .449-1 1.003zm-5.793-4.221l.793.795.793-.795a.998.998 0 011.414 0c.39.392.39 1.027 0 1.419l-.793.795.793.796c.39.392.39 1.027 0 1.419a.998.998 0 01-1.414 0L8 17.824l-.793.796a.998.998 0 01-1.414 0 1.006 1.006 0 010-1.419l.793-.796-.793-.795a1.006 1.006 0 010-1.42.998.998 0 011.414 0z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconCalculator;
