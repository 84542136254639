/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCheckboxCross({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.436 5.553a4.007 4.007 0 011.748-1.754C6.04 3.36 7.16 3.36 9.4 3.36h5.2c2.24 0 3.36 0 4.216.438a4.007 4.007 0 011.748 1.754C21 6.41 21 7.535 21 9.783v5.218c0 2.247 0 3.371-.436 4.23a4.007 4.007 0 01-1.748 1.754c-.856.437-1.976.437-4.216.437H9.4c-2.24 0-3.36 0-4.216-.437a4.007 4.007 0 01-1.748-1.754C3 18.372 3 17.248 3 15V9.783c0-2.248 0-3.372.436-4.23z"
          fill="#C9CBCC"
        />
        <path
          d="M9.707 8.672l2.293 2.3 2.293-2.3a.998.998 0 011.414 0c.39.392.39 1.027 0 1.42l-2.293 2.3 2.293 2.3c.39.392.39 1.028 0 1.42a.998.998 0 01-1.414 0L12 13.81l-2.293 2.3a.998.998 0 01-1.414 0 1.006 1.006 0 010-1.418l2.293-2.301-2.293-2.3a1.006 1.006 0 010-1.42.998.998 0 011.414 0z"
          fill="#212739"
        />
      </g>
    </svg>
  );
}

export default SvgIconCheckboxCross;
