/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import {
  DATA,
  DATA_SUCCESS,
  DATA_ERROR,
  SET_MODAL_TITLE,
  SET_MODAL_CONTENT,
  SET_MODAL_FOOTER,
  SET_MODAL_OPEN,
  CLOSE_MODAL,
} from './constants';

export const initialState = {
  loading: true,
  error: false,
  data: false,
  isOpen: false,
  modalTitle: null,
  modalContent: null,
  modalFooter: null,
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DATA:
        draft.loading = true;
        draft.error = false;
        break;

      case DATA_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        break;

      case DATA_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SET_MODAL_OPEN:
        draft.isOpen = !draft.isOpen;
        break;

      case CLOSE_MODAL:
        draft.isOpen = false;
        draft.modalTitle = null;
        draft.modalContent = null;
        draft.modalFooter = null;
        break;

      case SET_MODAL_TITLE:
        draft.modalTitle = action.title;
        break;

      case SET_MODAL_CONTENT:
        draft.modalContent = action.content;
        break;

      case SET_MODAL_FOOTER:
        draft.modalFooter = action.footer;
        break;
    }
  });

export default appReducer;
