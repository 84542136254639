/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconClose({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.069 5.477l.002.002.004.004a1 1 0 010 1.412l-5.661 5.678 5.66 5.68a1 1 0 010 1.413l-.003.003a.998.998 0 01-1.414 0L12 13.992 6.343 19.67a.998.998 0 01-1.414 0l-.004-.003a1 1 0 010-1.412l5.66-5.68-5.66-5.68a1 1 0 010-1.411l.004-.004a.998.998 0 011.414 0L12 11.154l5.657-5.675a.998.998 0 011.412-.002z"
        fill={primaryColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconClose;
