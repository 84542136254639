/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCheckbox({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.436 5.553a4.007 4.007 0 011.748-1.754C6.04 3.36 7.16 3.36 9.4 3.36h5.2c2.24 0 3.36 0 4.216.438a4.007 4.007 0 011.748 1.754C21 6.41 21 7.535 21 9.783v5.218c0 2.247 0 3.371-.436 4.23a4.007 4.007 0 01-1.748 1.754c-.856.437-1.976.437-4.216.437H9.4c-2.24 0-3.36 0-4.216-.437a4.007 4.007 0 01-1.748-1.754C3 18.372 3 17.248 3 15V9.783c0-2.248 0-3.372.436-4.23z"
        fill="#C9CBCC"
        stroke="#8992A0"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconCheckbox;
