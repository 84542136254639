/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconLock({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M8.161 9.747c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.298a3.005 3.005 0 00-1.311 1.315c-.193.38-.264.774-.296 1.171C4 12.937 4 13.393 4 13.922v4.693c0 .529 0 .985.03 1.361.033.397.104.791.297 1.171a3.005 3.005 0 001.311 1.315c.378.194.772.265 1.167.298.375.03.83.03 1.356.03h7.678c.527 0 .981 0 1.356-.03.395-.033.789-.104 1.167-.298a3.005 3.005 0 001.311-1.315c.193-.38.264-.774.296-1.17.031-.377.031-.833.031-1.362v-4.693c0-.529 0-.985-.03-1.36-.033-.398-.104-.792-.297-1.172a3.005 3.005 0 00-1.311-1.315c-.378-.194-.772-.265-1.167-.298-.375-.03-.83-.03-1.356-.03H8.16z"
          fill={secondaryColor}
        />
        <path
          d="M12 15.767c.552 0 1 .45 1 1.003v1.004c0 .514-.386.938-.883.996l-.117.007c-.552 0-1-.45-1-1.003V16.77c0-.514.386-.938.883-.996l.117-.007zm0-13.044c2.21 0 4 1.797 4 4.013v3.01h-2v-3.01a2.003 2.003 0 00-2-2.006c-1.105 0-2 .898-2 2.006v3.01H8v-3.01a4.007 4.007 0 014-4.013z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconLock;
