/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconRadioOff({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} {...props}>
      <ellipse
        cx={12}
        cy={12.041}
        rx={8.5}
        ry={8.53}
        transform="translate(0 .534)"
        stroke="#212739"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconRadioOff;
