/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconShieldCheck({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .534h24v24.08H0V.535z" />
        <path
          fill={secondaryColor}
          d="M12.37 2.108L20 3.544v9.326a8.534 8.534 0 01-4 7.23l-3.469 2.175a.998.998 0 01-1.062 0L8 20.1a8.534 8.534 0 01-4-7.23V3.544l7.63-1.436c.245-.046.495-.046.74 0z"
        />
        <path
          fill={primaryColor}
          d="M10.504 14.042l-1.916-1.923a.93.93 0 010-1.346c.383-.385 1.006-.385 1.341 0l1.246 1.25 3.163-3.174a.925.925 0 011.341 0 .932.932 0 010 1.347l-3.833 3.846a.945.945 0 01-.671.288.94.94 0 01-.67-.288z"
        />
      </g>
    </svg>
  );
}

export default SvgIconShieldCheck;
