/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconBack({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <path
        d="M9.518 17.004c-.541.54-1.418.54-1.959 0l-4.153-4.139a1.374 1.374 0 010-1.95l4.153-4.14a1.39 1.39 0 011.959 0c.54.54.54 1.413 0 1.951l-1.79 1.784h11.887c.765 0 1.385.618 1.385 1.38 0 .762-.62 1.38-1.385 1.38H7.727l1.79 1.783a1.376 1.376 0 010 1.951z"
        fill={primaryColor}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgIconBack;
