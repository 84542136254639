/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconPlus({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="a" d="M0 0h24v24H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M13 8v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 0 1 0-2h3V8a1 1 0 0 1 2 0z"
          fill={primaryColor}
          mask="url(#b)"
        />
      </g>
    </svg>
  );
}

export default SvgIconPlus;
