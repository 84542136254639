/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconProfileCustomer({ className }) {
  return (
    // <svg
    //   className={className}
    //   width="61"
    //   height="61"
    //   viewBox="0 0 65 57"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M34.8805 44.486C34.8779 44.4875 34.8754 44.489 34.8729 44.4904C34.8766 44.4907 34.8802 44.4909 34.8838 44.4912C34.8827 44.4895 34.8816 44.4878 34.8805 44.486ZM5.4757 15.5256C5.97942 13.9482 6.7979 12.5844 7.82735 11.4067C7.92122 11.1917 8.07705 11.0292 8.26664 10.9247C10.2332 8.89093 12.8621 7.47087 15.6103 6.56563C25.0476 3.45643 37.5274 4.61293 43.5127 13.3612C43.6317 13.5357 43.6831 13.7074 43.6911 13.8708C44.29 14.9453 44.6847 16.1617 44.8451 17.3757C45.2668 20.5561 44.5071 23.9498 45.8811 26.965C46.8811 29.1588 48.4549 30.8983 48.8641 33.3481C49.5351 37.3634 47.6605 41.5548 44.5787 44.1467C41.0758 47.0925 36.1919 47.6325 31.8811 46.3384C26.9578 44.8603 23.666 41.3376 20.8124 37.2651C19.3325 35.1527 17.9242 32.9635 16.0499 31.1637C14.1314 29.3207 11.7236 28.1565 9.58854 26.5988C5.92707 23.928 4.0559 19.9731 5.4757 15.5256V15.5256Z"
    //     fill="#E2000F"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M48.7263 39.1514C46.2641 38.6354 41.0318 37.3702 39.8482 34.4016C38.6646 31.4331 38.6646 25.4959 38.6646 25.4959H26.8273C26.8273 25.4959 26.8273 31.4331 25.6437 34.4016C24.4602 37.3702 19.2284 38.6354 16.7661 39.1514C12.0503 40.1403 13.0864 42.8119 15.3009 45.2365C18.0263 48.2206 21.9332 49.8384 25.9675 49.8384H39.5244C43.5588 49.8384 47.4652 48.2206 50.191 45.2365C52.4056 42.8119 53.4411 40.1403 48.7263 39.1514"
    //     fill="#FFFFFE"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M48.7263 39.1514C46.2641 38.6354 41.0318 37.3702 39.8482 34.4016C38.6646 31.4331 38.6646 25.4959 38.6646 25.4959H26.8273C26.8273 25.4959 26.8273 31.4331 25.6437 34.4016C24.4602 37.3702 19.2284 38.6354 16.7661 39.1514C12.0503 40.1403 13.0864 42.8119 15.3009 45.2365C18.0263 48.2206 21.9332 49.8384 25.9675 49.8384H39.5244C43.5588 49.8384 47.4652 48.2206 50.191 45.2365C52.4056 42.8119 53.4411 40.1403 48.7263 39.1514Z"
    //     stroke="#1B1A1C"
    //     strokeWidth="0.5"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M39.2859 32.4075C38.5474 29.294 38.6651 25.4959 38.6651 25.4959H26.8277C26.8277 25.4959 26.8277 31.4331 25.6441 34.4016C25.2798 35.3157 24.5305 36.0679 23.5972 36.6866C23.6203 36.6948 23.6439 36.701 23.6671 36.7093C31.283 37.563 34.6436 36.3504 39.2859 32.4075"
    //     fill="#9DA19E"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M48.7263 39.1514C46.2641 38.6354 41.0318 37.3702 39.8482 34.4016C38.6646 31.4331 38.6646 25.4959 38.6646 25.4959H26.8273C26.8273 25.4959 26.8273 31.4331 25.6437 34.4016C24.4602 37.3702 19.2284 38.6354 16.7661 39.1514C12.0503 40.1403 13.0864 42.8119 15.3009 45.2365C18.0263 48.2206 21.9332 49.8384 25.9675 49.8384H39.5244C43.5588 49.8384 47.4652 48.2206 50.191 45.2365C52.4056 42.8119 53.4411 40.1403 48.7263 39.1514Z"
    //     stroke="#1B1A1C"
    //     strokeWidth="0.5"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M63.1083 54.5078C62.1 48.3876 58.2337 43.0439 52.6196 40.4434C51.4375 39.8959 50.1409 39.4479 48.7265 39.1514C47.3554 38.8637 45.126 38.3435 43.2141 37.4269C34.2949 42.4948 26.1522 39.7381 21.9873 37.5625C20.1444 38.3956 18.0681 38.8782 16.7663 39.1514C13.5979 39.816 11.0221 41.2394 8.99828 42.8382C5.55238 45.5598 3.23148 49.4646 2.33262 53.771L1 59.9196H64L63.1083 54.5078"
    //     fill="black"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M63.1083 54.5078C62.1 48.3876 58.2337 43.0439 52.6196 40.4434C51.4375 39.8959 50.1409 39.4479 48.7265 39.1514C47.3554 38.8637 45.126 38.3435 43.2141 37.4269C34.2949 42.4948 26.1522 39.7381 21.9873 37.5625C20.1444 38.3956 18.0681 38.8782 16.7663 39.1514C13.5979 39.816 11.0221 41.2394 8.99828 42.8382C5.55238 45.5598 3.23148 49.4646 2.33262 53.771L1 59.9196H64L63.1083 54.5078Z"
    //     stroke="#1B1A1C"
    //     strokeWidth="0.5"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M43.9253 15.7086C43.9253 15.0234 43.4735 14.3424 42.568 14.1227C42.1651 7.46329 39.3893 2.31598 32.7165 2.31598C26.0662 2.31598 23.2869 7.42926 22.8701 14.0557C21.6459 14.1222 21.0405 14.9131 21.0405 15.7086C21.0405 16.4592 21.8654 17.4811 22.8773 17.4228C23.4545 24.1106 27.7555 28.8109 32.7165 28.8109C38.3522 28.8109 42.0895 24.0905 42.5706 17.3785C43.3497 17.1583 43.9253 16.336 43.9253 15.7086"
    //     fill="#FFFFFE"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M43.9253 15.7086C43.9253 15.0234 43.4735 14.3424 42.568 14.1227C42.1651 7.46329 39.3893 2.31598 32.7165 2.31598C26.0662 2.31598 23.2869 7.42926 22.8701 14.0557C21.6459 14.1222 21.0405 14.9131 21.0405 15.7086C21.0405 16.4592 21.8654 17.4811 22.8773 17.4228C23.4545 24.1106 27.7555 28.8109 32.7165 28.8109C38.3522 28.8109 42.0895 24.0905 42.5706 17.3785C43.3497 17.1583 43.9253 16.336 43.9253 15.7086Z"
    //     stroke="#1B1A1C"
    //     strokeWidth="0.5"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M42.4263 16.3247C42.4248 16.1422 42.4623 15.9669 42.5183 15.7942C42.5784 15.622 42.6463 15.4492 42.8349 15.3059C42.9253 15.2332 43.1222 15.2219 43.2142 15.3137C43.2645 15.3503 43.2902 15.3951 43.3267 15.4364C43.3745 15.5054 43.3735 15.5642 43.375 15.6147C43.3725 15.8137 43.3211 15.9818 43.2409 16.1463C43.2167 15.9689 43.2152 15.7875 43.1802 15.639C43.1695 15.6044 43.1546 15.573 43.1489 15.5725C43.1227 15.5539 43.099 15.5225 43.0764 15.5132C43.0307 15.4848 43.022 15.4951 42.9742 15.5214C42.877 15.5833 42.7845 15.7385 42.6961 15.8766C42.6082 16.0205 42.5255 16.1731 42.4263 16.3247"
    //     fill="#0A090B"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M22.5405 16.3247C22.4413 16.1731 22.3586 16.0205 22.2707 15.8766C22.1818 15.7385 22.0893 15.5833 21.9927 15.5214C21.9449 15.4951 21.9362 15.4848 21.8899 15.5132C21.8673 15.5225 21.8436 15.5539 21.818 15.5725C21.8118 15.573 21.7969 15.6044 21.7866 15.639C21.7517 15.7875 21.7496 15.9689 21.7254 16.1463C21.6458 15.9818 21.5939 15.8137 21.5913 15.6147C21.5934 15.5642 21.5918 15.5054 21.6396 15.4364C21.6761 15.3951 21.7018 15.3503 21.7522 15.3137C21.8447 15.2219 22.041 15.2332 22.132 15.3059C22.3201 15.4492 22.3884 15.622 22.4485 15.7942C22.5046 15.9669 22.5416 16.1422 22.5405 16.3247"
    //     fill="#0A090B"
    //   />
    //   <path d="M16.4855 52.7956V60" stroke="#1B1A1C" strokeWidth="0.5" />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M26.9868 7.93453C33.1899 6.6544 32.8641 6.53324 38.7311 7.86286C42.9633 8.8218 42.065 16.2551 42.065 16.2551C42.065 16.2551 46.0603 1.4777 32.9597 0.0748757C21.4369 -1.15937 21.6831 13.2236 23.2839 16.7949C23.2839 16.7949 23.0943 8.73776 26.9868 7.93453Z"
    //     fill="#1B1A1C"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M42.065 16.2551C41.8517 17.4579 40.6583 21.8762 36.6271 20.4311C34.1129 19.5304 32.73 20.8611 32.73 20.8611C30.3453 18.9159 28.8477 20.8611 26.2971 20.8611C24.7296 20.8611 23.6231 18.3879 23.2839 16.3247C23.1508 15.5142 22.8065 13.7613 22.8065 13.7613C22.4863 15.5637 22.7448 28.6181 32.5259 28.878C41.938 29.1285 43.0651 18.6772 42.4951 13.12L42.065 16.2551"
    //     fill="#1B1A1C"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M25.1187 13.0937C26.0838 12.5854 27.1255 12.2018 28.2233 12.0322C28.7701 11.9492 29.3406 11.9182 29.9033 12.042C30.4604 12.1533 31.0273 12.4395 31.3917 12.9174C31.7781 13.3814 31.922 13.98 31.9935 14.5259C32.0685 15.0807 32.0942 15.6313 32.1071 16.1814C32.1389 17.2821 32.0588 18.3756 31.9688 19.4675L31.8727 19.3608C32.4838 19.399 33.0959 19.4232 33.7121 19.4737C33.0989 19.516 32.4827 19.532 31.865 19.5614L31.7658 19.5665L31.7689 19.4546C31.8008 18.3673 31.7745 17.2754 31.7417 16.1932C31.718 15.6524 31.6815 15.1111 31.5998 14.5852C31.5114 14.0624 31.3937 13.5526 31.0818 13.1607C30.7883 12.7596 30.3253 12.5075 29.8252 12.3951C29.3236 12.2822 28.7881 12.2714 28.2577 12.3193C27.1944 12.4266 26.1398 12.7189 25.1187 13.0937"
    //     fill="#1B1A1C"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M40.3742 13.1597C39.8212 12.9983 39.2765 12.8359 38.7327 12.7431C38.1875 12.6545 37.6401 12.6565 37.0995 12.7442C36.0115 12.9107 34.9703 13.3628 33.8931 13.7614C34.813 13.0777 35.8645 12.5282 37.0373 12.3353C37.6216 12.2487 38.2332 12.2663 38.8006 12.4287C39.3705 12.5844 39.888 12.8535 40.3742 13.1597"
    //     fill="#1B1A1C"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M28.5225 15.871C28.5225 16.2267 28.3467 16.5149 28.1304 16.5149C27.9145 16.5149 27.7388 16.2267 27.7388 15.871C27.7388 15.5158 27.9145 15.2276 28.1304 15.2276C28.3467 15.2276 28.5225 15.5158 28.5225 15.871"
    //     fill="#1B1A1C"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M37.0186 15.871C37.0186 16.2267 36.8434 16.5149 36.627 16.5149C36.4106 16.5149 36.2349 16.2267 36.2349 15.871C36.2349 15.5158 36.4106 15.2276 36.627 15.2276C36.8434 15.2276 37.0186 15.5158 37.0186 15.871"
    //     fill="#1B1A1C"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M26.3507 18.8643C26.3507 18.9679 26.267 19.0514 26.1642 19.0514C26.0614 19.0514 25.9776 18.9679 25.9776 18.8643C25.9776 18.7612 26.0614 18.6771 26.1642 18.6771C26.267 18.6771 26.3507 18.7612 26.3507 18.8643"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M27.9255 18.1626C27.9255 18.2657 27.8417 18.3498 27.7389 18.3498C27.6356 18.3498 27.5523 18.2657 27.5523 18.1626C27.5523 18.059 27.6356 17.9755 27.7389 17.9755C27.8417 17.9755 27.9255 18.059 27.9255 18.1626"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M27.9255 19.484C27.9255 19.5871 27.8417 19.6712 27.7389 19.6712C27.6356 19.6712 27.5523 19.5871 27.5523 19.484C27.5523 19.3804 27.6356 19.2969 27.7389 19.2969C27.8417 19.2969 27.9255 19.3804 27.9255 19.484"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M29.1491 18.6772C29.1491 18.7808 29.0658 18.8643 28.9625 18.8643C28.8592 18.8643 28.776 18.7808 28.776 18.6772C28.776 18.574 28.8592 18.49 28.9625 18.49C29.0658 18.49 29.1491 18.574 29.1491 18.6772"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M38.7569 18.8643C38.7569 18.9679 38.8402 19.0514 38.9435 19.0514C39.0463 19.0514 39.13 18.9679 39.13 18.8643C39.13 18.7612 39.0463 18.6771 38.9435 18.6771C38.8402 18.6771 38.7569 18.7612 38.7569 18.8643"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M37.1822 18.1626C37.1822 18.2657 37.2655 18.3498 37.3688 18.3498C37.4716 18.3498 37.5553 18.2657 37.5553 18.1626C37.5553 18.059 37.4716 17.9755 37.3688 17.9755C37.2655 17.9755 37.1822 18.059 37.1822 18.1626"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M37.1822 19.484C37.1822 19.5871 37.2655 19.6712 37.3688 19.6712C37.4716 19.6712 37.5553 19.5871 37.5553 19.484C37.5553 19.3804 37.4716 19.2969 37.3688 19.2969C37.2655 19.2969 37.1822 19.3804 37.1822 19.484"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M35.9586 18.6772C35.9586 18.7808 36.0418 18.8643 36.1451 18.8643C36.2479 18.8643 36.3317 18.7808 36.3317 18.6772C36.3317 18.574 36.2479 18.49 36.1451 18.49C36.0418 18.49 35.9586 18.574 35.9586 18.6772"
    //     fill="#E9BCAF"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M30.4326 21.8762C30.7821 22.1288 31.1495 22.3319 31.5196 22.4984C31.8927 22.6572 32.2797 22.7629 32.6672 22.7681C33.0521 22.7794 33.4432 22.7057 33.8045 22.5294C33.9967 22.4644 34.1581 22.3345 34.3441 22.2391C34.523 22.1314 34.6797 21.9906 34.8678 21.8762C34.7486 22.054 34.616 22.2283 34.4726 22.3953C34.3071 22.5366 34.1591 22.7119 33.9566 22.814C33.5856 23.0722 33.1143 23.1893 32.6559 23.181C31.7236 23.1486 30.9039 22.5933 30.4326 21.8762"
    //     fill="#FFFFFE"
    //   />
    // </svg>
    <svg width="61" height="61" viewBox="0 0 603 603" fill="none" className={className}>

    <path d="M276.267 78.6667C263.6 82 256.4 85.3334 245.333 92.6667C227.467 104.533 212.933 123.733 205.867 144.667C201.067 159.067 199.867 181.467 203.333 196.8C211.2 232.667 237.467 261.067 273.333 272.267C281.733 274.8 286.133 275.333 301.333 275.333C316.533 275.333 320.933 274.8 329.333 272.267C358 263.333 379.6 244.533 392.133 218C399.467 202.267 401.867 189.6 401.067 170.8C399.867 143.867 390.933 123.867 371.467 104.667C358.8 92.2667 349.867 86.5334 333.733 80.9334C323.2 77.3334 320 76.8 304 76.4C289.333 76.1334 284.133 76.5334 276.267 78.6667Z" fill="#E2000F"/>
    <path d="M264.8 354C212.267 360.667 160.133 376.4 127.2 395.6C95.0667 414.533 78.6667 435.333 75.7333 460.933C73.8667 477.467 75.0667 507.6 77.7333 512.8C80.4 518 88.2667 524.933 93.0667 526.267C97.7333 527.6 504.933 527.6 509.6 526.267C514.4 524.933 522.267 518 524.933 512.8C527.6 507.6 528.8 477.467 526.933 460.933C525.867 451.733 524.533 446.8 520.533 438.933C502.133 401.2 438.133 369.733 352 355.867C330.133 352.267 285.467 351.333 264.8 354Z" fill="#E2000F"/>
    </svg>
  );
}

export default SvgIconProfileCustomer;
