// src/utils/DynamicMetaDescription.js
import React from 'react';
import { Helmet } from 'react-helmet';

const DynamicMetaDescription = () => {
  const domain = window.location.hostname;
  //const path = window.location.pathname;
  let description = "";

  if (domain === "talep.viennalife.com.tr") {
    description =
    "Poliçelerinize ait tüm değişiklik taleplerinizi Talep Yönetim Sistemi üzerinden gerçekleştirerek, 7/24 kesintisiz olarak bize ulaştırabilirsiniz.";
  } else if (domain === "vtouch.com.tr") {
    description =
    "Tüm hayat sigortası işlemleriniz VTouch ile yanıbaşınızda! VTouch ile hayat sigortası poliçelerinize ait işlemlerinizi kolayca gerçekleştirebilirsiniz.";
  }

  return description ? (
    <Helmet>
      <meta name="description" content={description} />
    </Helmet>
  ) : null;
};

export default DynamicMetaDescription;
