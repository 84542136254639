/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconChart({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.327 5.005A3.005 3.005 0 014.638 3.69C5.28 3.36 6.12 3.36 7.8 3.36h8.4c1.68 0 2.52 0 3.162.329.564.288 1.023.749 1.311 1.315C21 5.649 21 6.492 21 8.178v8.428c0 1.686 0 2.529-.327 3.173a3.005 3.005 0 01-1.311 1.315c-.642.328-1.482.328-3.162.328H7.8c-1.68 0-2.52 0-3.162-.328a3.005 3.005 0 01-1.311-1.315C3 19.135 3 18.292 3 16.606V8.178c0-1.686 0-2.529.327-3.173z"
          fill={secondaryColor}
        />
        <path
          d="M18.949 6.689l-1.977 5.95c-.705 2.123-3.39 2.759-4.967 1.177l-1.424-1.429a.999.999 0 00-1.656.392L6.95 18.73a1 1 0 11-1.898-.634l1.977-5.95c.705-2.123 3.39-2.759 4.967-1.177l1.424 1.429a.999.999 0 001.656-.393l1.976-5.95a1 1 0 111.898.635z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconChart;
