/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconRadioOn({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} {...props}>
      <g transform="translate(0 .534)" fill="none" fillRule="evenodd">
        <ellipse cx={12} cy={12.041} stroke="#212739" rx={8.5} ry={8.53} />
        <ellipse cx={12} cy={12.041} fill="#E2000F" rx={5} ry={5.017} />
      </g>
    </svg>
  );
}

export default SvgIconRadioOn;
