export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const POLICY_TYPES = [
  { name: 'EGTS', color: '#ff4848' },
  { name: 'ROP', color: '#e2000f' },
  { name: 'TPG', color: '#c36360' },
  { name: 'TE', color: '#b72c82' },
  { name: 'GTE', color: '#b72c82' },
  { name: 'EY_E', color: '#b72c82' },
  { name: 'YBGE', color: '#b72c82' },
  { name: 'SGTE', color: '#b72c82' },
  { name: 'ÖGS', color: '#b72c82' },
  { name: 'ROL', color: '#861815' },
  { name: 'KH', color: '#e9873c' },
  { name: 'SSGH', color: '#e9873c' },
  { name: 'GK', color: '#e9873c' },
  { name: 'BSVS', color: '#b72c82' },
  { name: 'RPAD', color: '#e2000f' },
  { name: 'RPFR', color: '#e2000f' },
  { name: 'ACY', color: '#b72c82' },
  { name: 'KG', color: '#b72c82' },
  { name: 'RPADFR', color: '#e2000f' },
  { name: 'RP', color: '#e2000f' },
  { name: 'BDP', color: '#e2000f' },
  { name: 'GHSOB', color: '#b72c82' },

];

export const PRODUCT_TYPES = [
  {
    name: 'all',
    tariffs: ['BDP', 'EGTS', 'ROP', 'RPFR', 'RPAD', 'TPG', 'TE', 'GTE', 'YBGE', 'SGTE', 'ÖGS', 'ROL', 'KH', 'SSGH', 'GK', 'FAYTS', 'BSVS','ACY','KG','RPADFR','RP','EY_E', 'GHSOB'],
  },
  { name: 'egitim', tariffs: ['BDP', 'EGTS'] },
  { name: 'primIadeli', tariffs: ['ROP', 'RPFR', 'RPAD', 'RPADFR','RP'] },
  { name: 'tekPrimliler', tariffs: ['TPG'] },
  { name: 'turuncuElma', tariffs: ['TE', 'GTE', 'YBGE', 'SGTE', 'ÖGS', 'BSVS','ACY','KG','EY_E', 'GHSOB'] },
  { name: 'odulluBirikim', tariffs: ['ROL', 'ROP', 'EGTS'] },
  { name: 'krediHayat', tariffs: ['KH', 'SSGH', 'GK', 'FAYTS'] },
];

export const profileFields = [
  { name: 'contactInfo.phoneNumber1', point: 10 },
  { name: 'contactInfo.phoneNumber2', point: 10 },
  { name: 'contactInfo.address', point: 10 },
  { name: 'contactInfo.email', point: 10 },
  { name: 'occupationInfo.occupation', point: 10 },
  { name: 'userInfo.fullName', point: 5 },
  { name: 'userInfo.birthday', point: 5 },
  { name: 'userInfo.birthplace', point: 5 },
  { name: 'userInfo.maritalStatus', point: 5 },
  { name: 'userInfo.gender', point: 5 },
  { name: 'userInfo.fatherName', point: 5 },
  { name: 'userInfo.motherName', point: 5 },
  { name: 'occupationInfo.company', point: 5 },
];

export const TABLE_PAYMENTS = {
  paidColumns: [
    { title: 'POLID', key: 'polId' },
    { title: 'Poliçe Adı', key: 'polName' },
    { title: 'Ödeme Tarihi', key: 'paymentDate' },
    { title: 'Ödenen Tutar', key: 'paymentAmount' },
    { title: 'Durum Bilgisi', key: 'paymentStatus' },
    { title: 'İndir', key: 'download' },
    { title: 'Email Gönder', key: 'sendEmail' },
  ],
  nonPaidColumns: [
    { title: 'POLID', key: 'polId' },
    { title: 'Poliçe Adı', key: 'polName' },
    { title: 'Ödeme Tarihi', key: 'paymentDate' },
    { title: 'Ödenecek Tutar', key: 'paymentAmount' },
    { title: 'Durum Bilgisi', key: 'paymentStatus' },
  ],
  belatedPaymentColumns: [
    { title: 'POLID', key: 'polId' },
    { title: 'Poliçe Adı', key: 'polName' },
    { title: 'Ödeme Tarihi', key: 'paymentDate' },
    { title: 'Ödenecek Tutar', key: 'paymentAmount' },
    { title: 'Durum Bilgisi', key: 'paymentStatus' },
  ],
  receiptsColumns: [
    { title: 'POLID', key: 'polId' },
    { title: 'Poliçe Adı', key: 'polName' },
    { title: 'Vade Tarihi', key: 'dueDate' },
    { title: 'Ödeme Tarihi', key: 'paymentDate' },
    { title: 'Ödenen Tutar', key: 'paymentAmount' },
    { title: 'İndir', key: 'download' },
    { title: 'Email Gönder', key: 'sendEmail' },
  ],
};

export const BADGE_STYLES = {
  belatedPayments: {
    backgroundColor: '#fdf2f3',
    color: '#e2000f',
    border: '1px solid #e2000f',
    fontFamily: 'Rubik',
    fontSize: '0.75rem',
    fontWeight: '500',
    padding: '0.5rem 1rem',
    borderRadius: '15px',
    margin: '0 5px 0 5px',
  },
  nonPayments: {
    backgroundColor: '#fffbf2',
    color: '#ffb100',
    border: '1px solid #ffb100',
    fontFamily: 'Rubik',
    fontSize: '0.75rem',
    fontWeight: '500',
    padding: '0.5rem 1rem',
    borderRadius: '15px',
    margin: '0 5px 0 5px',
  },
  payments: {
    backgroundColor: '#f6fbf9',
    color: '#55b186',
    border: '1px solid #55b186',
    fontFamily: 'Rubik',
    fontSize: '0.75rem',
    fontWeight: '500',
    margin: '0 5px 0 5px',
    padding: '0.5rem 1rem',
    borderRadius: '15px',
  },
  paymentsButtons: {
    margin: '0 5px 0 5px',
    padding: '0.4rem 1rem',
    borderRadius: '15px',
    border: 'solid 1px #212739',
    backgroundColor: '#fff',
    color: '#212739',
    fontfamily: 'Rubik',
    fontSize: '0.75rem',
    fontWeight: '500',
  },
  paymentsLabel: {
    margin: '0 0.5rem',
    cursor:'pointer',
  },
};

export const DOCUMENT_STYLES = {
  documentButtons: {
    margin: '0 5px 0 5px',
    padding: '0.4rem 1rem',
    borderRadius: '15px',
    border: 'solid 1px #212739',
    backgroundColor: '#fff',
    color: '#212739',
    fontfamily: 'Rubik',
    fontSize: '0.75rem',
    fontWeight: '500',
  },
  documentLabel: {
    margin: '0 0.5rem',
    cursor:'pointer',
  },
};

export const TABLE_DOCUMENTS = {
  documentsListColumns: [
    // { title: 'Tarih', key: 'paymentDate' },
    { title: 'Doküman Adı', key: 'documentType', sorting: false },
    { title: 'Doküman Numarası', key: 'documentId', sorting: false },
    { title: 'E-posta Gönder', key: 'sendEmail' },
    { title: 'İndir', key: 'download' },
  ],
};

export const TAB_NAMES = ['belatedPayments', 'nonPayments', 'payments'];

export const DEFAULT_ARRAY = {
  dropDownName: 'TÜM POLİÇELER',
  polId: 1,
  polName: 'TÜM POLİÇELER',
};

export const PASSIVE_POLICY_KEYS = ['revivablePolicies', 'rightLossPolicies', 'duePolicies'];

export const guaranteeNamesPremiumReturn = ['deathBenefit', 'expiryReturnAmount', 'primiadelitesvikodulu', 'nextPaymentDate'];
export const guaranteeNamesEducation = ['policydeathbenefit', 'savingAmount', 'nextPaymentDate'];
export const guaranteeNamesSinglePremium = ['deathBenefit', 'policyExpiryPremiumReturn'];
export const guaranteeNamesGTE = ['deathBenefit', 'criticalDiseaseBenefit', 'nextPaymentDate'];
export const guaranteeNamesRewarded = ['policydeathbenefit', 'savingAmount', 'bonusInvestment', 'nextPaymentDate'];
export const guaranteeNamesCreditLife = ['deathBenefit', 'nextPaymentDate'];

export const homePageGuaranteeNamesROPFTFP = ['deathBenefit', 'nextPaymentDate'];
export const homePageGuaranteeNamesPremiumReturn = ['deathBenefit', 'expiryReturnAmount', 'nextPaymentDate'];
export const homePageGuaranteeNamesEducation = ['policydeathbenefit', 'savingAmount', 'nextPaymentDate'];
export const homePageGuaranteeNamesSinglePremium = ['deathBenefit', 'policyExpiryPremiumReturn', 'nextPaymentDate'];
export const homePageGuaranteeNamesGTE = ['deathBenefit', 'criticalDiseaseBenefit', 'nextPaymentDate'];
export const homePageGuaranteeNamesRewarded = ['policydeathbenefit', 'savingAmount', 'bonusInvestment', 'nextPaymentDate'];
export const homePageGuaranteeNamesCreditLife = ['deathBenefit', 'nextPaymentDate'];

export const DEMAND_SUBJECT_DATA = {
  mobilePhoneChange: 'ACCOUNT_ADD_CELL_PHONE',
  changeOfInsurer: 'STAKEHOLDERS_UPDATE_POLICY_HOLDER',
  changeOfInsured: 'STAKEHOLDERS_UPDATE_INSURED',
  changeRightholder: 'STAKEHOLDERS_UPDATE_BENEFICIARY',
  suspendDemand: 'POLICYTERMS_PREMIUM_HOLIDAY',
  premiumChange: 'POLICYTERMS_PREMIUM_CHANGE',
  collateralTransactions: 'POLICYTERMS_RIDERS',
  leavingDemand: 'POLICYSTATUS_TERMINATION',
  expirationDate: 'POLICYSTATUS_LAPSE',
  reEnforcement: 'POLICYSTATUS_REINSTATEMENT',
  debtGetDemand: 'ACCOUNTSPAYABLE_LOAN_PAYMENT',
  debtRepaymentDemand: 'RECEIVABLECOLLECTION_LOAN_PAYBACK',
  PaymentRefundProcess: 'PAYMENT_REFUND',
};
export let CALL_CENTER_LOG_SUBJECT_DATA = [
  {
    label:'Tahsilatları hakkında bilgilendirme',
    value:'INFORMATION_COLLECTION'
  },
  {
    label:'Yeniden yürürlük hakkında bilgilendirme',
    value:'INFORMATION_REINSTATEMENT'
  },
  {
    label:'Tazminat işlemleri hakkında bilgilendirme',
    value:'INFORMATION_CLAIM'
  },
  {
    label:'Ödeme işlemleri hakkında bilgilendirme',
    value:'INFORMATION_PAYMENT'
  },
  {
    label:'Poliçesi hakkında bilgilendirme',
    value:'INFORMATION_POLICY'
  },
  {
    label:'Genel',
    value:'INFORMATION_GENERAL'
  },
]

export const addToCallCenterLogSubjectData = (label, value) => {
  if (!CALL_CENTER_LOG_SUBJECT_DATA.some((o) => o.value === value)) {
    CALL_CENTER_LOG_SUBJECT_DATA.push({ label, value });
  }
};

export const updateCallCenterLogSubjectData = (label, value) => addToCallCenterLogSubjectData(label, value);

export const removeCallCenterLogSubjectData = (value) => {
  CALL_CENTER_LOG_SUBJECT_DATA.splice(CALL_CENTER_LOG_SUBJECT_DATA.indexOf(o => o.value === value), 1);
};


export const DOMAIN = {vtouch: ["preprod.vtouch.com.tr", "test.vtouch.com.tr", "vtouch.com.tr", "www.vtouch.com.tr"], viennalife: ["talep-test.viennalife.com.tr", "talep.viennalife.com.tr", "www.talep.viennalife.com.tr"], aegon: ["talep-test.aegon.com.tr", "talep.aegon.com.tr", "www.talep.aegon.com.tr"]}
