/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconEyeOff({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.788 5.926L18.85 18.028l-.945.69c-1.49 1.09-3.436 1.884-5.904 1.884-6.108 0-8.86-4.804-9.73-6.763a3.108 3.108 0 010-2.529c.526-1.184 1.693-3.32 3.827-4.879l.691-.505zM12 4.547c6.11 0 8.861 4.804 9.73 6.763.359.808.359 1.721 0 2.529-.155.35-.364.777-.634 1.245a.998.998 0 01-1.366.366 1.005 1.005 0 01-.365-1.371c.23-.398.407-.761.538-1.057a1.095 1.095 0 000-.896C19.11 10.34 16.856 6.554 12 6.554h-1c-.552 0-1-.45-1-1.003 0-.554.448-1.004 1-1.004h1z"
          fill={secondaryColor}
        />
        <path
          d="M3.707 2.834l18 18.061c.39.392.39 1.027 0 1.42a.998.998 0 01-1.414 0l-7-7.023a2.98 2.98 0 01-1.293.293c-1.657 0-3-1.348-3-3.01 0-.465.105-.905.292-1.298l-7-7.024a1.006 1.006 0 010-1.419.998.998 0 011.415 0z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconEyeOff;
