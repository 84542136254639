/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconFacebook({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.003 3.726H3.997c-.549.004-.993.45-.997 1.001v16.06c.004.55.448.996.998 1h8.617v-6.983h-2.34v-2.732h2.34v-2.01c0-2.332 1.418-3.604 3.502-3.604.698 0 1.395 0 2.093.105v2.431h-1.433c-1.132 0-1.35.542-1.35 1.332v1.738h2.7l-.352 2.732h-2.348v6.991h4.575c.55-.004.994-.45.998-1V4.726a1.007 1.007 0 00-.998-1z"
        fill="#212739"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconFacebook;
