/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconSubmitted({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 25 25"
      {...props}
    >
      <defs>
        <path id="icon-submitted_svg__a" d="M0 0h24v24.081H0z" />
      </defs>
      <g transform="translate(0 .534)" fill="none" fillRule="evenodd">
        <mask id="icon-submitted_svg__b" fill="#fff">
          <use xlinkHref="#icon-submitted_svg__a" />
        </mask>
        <path
          fill={secondaryColor}
          d="M9.758 4.014H6.16c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.298a3.007 3.007 0 00-1.311 1.315c-.193.38-.264.774-.296 1.171C2 7.204 2 7.66 2 8.19v7.703c0 .53 0 .985.03 1.361.033.397.104.791.297 1.171a3.01 3.01 0 001.311 1.316c.378.193.772.265 1.167.297.375.03.83.03 1.356.03H17.84c.527 0 .981 0 1.356-.03.395-.032.789-.104 1.167-.297a3.015 3.015 0 001.311-1.316c.193-.38.264-.774.296-1.17.031-.377.031-.833.031-1.362v-5.696c0-.53 0-.985-.03-1.361-.033-.397-.104-.791-.297-1.171a3.015 3.015 0 00-1.311-1.316c-.378-.193-.772-.265-1.167-.297-.375-.03-.83-.03-1.356-.03h-4.425l-.829-.832c-.287-.29-.54-.544-.846-.732a2.997 2.997 0 00-.867-.36c-.349-.084-.707-.084-1.114-.083z"
          mask="url(#icon-submitted_svg__b)"
        />
        <path
          fill={primaryColor}
          d="M15.707 13.753l-2 2.007a.997.997 0 01-1.414 0 1.007 1.007 0 010-1.419l.293-.294H9c-.552 0-1-.449-1-1.003 0-.554.448-1.003 1-1.003h3.586l-.293-.294a1.008 1.008 0 010-1.42.999.999 0 011.414 0l2 2.007c.39.392.39 1.028 0 1.42z"
          mask="url(#icon-submitted_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgIconSubmitted;
