/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconLocation({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3 11.753c0 3.037 1.81 5.611 3.895 7.424l3.441 2.993c.955.83 2.373.83 3.328 0l3.44-2.993C19.19 17.364 21 14.79 21 11.753c0-4.987-4.03-9.03-9-9.03s-9 4.043-9 9.03z"
          fill={secondaryColor}
        />
        <path
          d="M12 13.76c-1.105 0-2-.898-2-2.007 0-1.108.895-2.006 2-2.006s2 .898 2 2.006a2.003 2.003 0 01-2 2.007z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconLocation;
