/* eslint-disable react/prop-types */
import * as React from 'react';

function IconUserWithGuard({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg viewBox="0 0 50 50" width={size} height={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={primaryColor} fillRule="evenodd" transform="matrix(1.296198, 0, 0, 1.12067, -10.153116, -4.108377)">
        <path d="M17 25.667h11a9.167 9.167 0 0 1 9.167 9.166 5.5 5.5 0 0 1-5.5 5.5H13.333a5.5 5.5 0 0 1-5.5-5.5A9.167 9.167 0 0 1 17 25.667zM13.333 12.833a9.167 9.167 0 1 0 18.334 0 9.167 9.167 0 0 0-18.334 0z" />
      </g>
      <g fill="none" fillRule="evenodd" transform="matrix(1.296198, 0, 0, 1.12067, 10.465971, 16.379898)">
        <path d="M.5 0h30v30H.5V0z" />
        <path
          d="M15.869 1.944 25.5 3.75v11.604c0 3.668-1.89 7.077-5 9.02l-4.47 2.795a1 1 0 0 1-1.06 0l-4.47-2.794c-3.11-1.944-5-5.353-5-9.021V3.75l9.631-1.806a2 2 0 0 1 .738 0z"
          fill="#FFF"
        />
        <path
          d="M 13.635 16.828 L 11.235 14.432 C 10.755 13.976 10.755 13.211 11.235 12.755 C 11.715 12.276 12.495 12.276 12.915 12.755 L 14.475 14.312 L 18.434 10.359 C 18.915 9.88 19.634 9.88 20.114 10.359 C 20.595 10.839 20.595 11.557 20.114 12.036 L 15.314 16.828 C 15.074 17.068 14.774 17.188 14.475 17.188 C 14.174 17.188 13.874 17.068 13.635 16.828 Z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default IconUserWithGuard;
