/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconMobile({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 25 25"
      {...props}
    >
      <defs>
        <path id="icon-mobile_svg__a" d="M0 0h24v24.081H0z" />
      </defs>
      <g transform="translate(0 .534)" fill="none" fillRule="evenodd">
        <mask id="icon-mobile_svg__b" fill="#fff">
          <use xlinkHref="#icon-mobile_svg__a" />
        </mask>
        <path
          fill={secondaryColor}
          d="M4.327 3.65a3.007 3.007 0 011.311-1.315c.642-.328 1.482-.328 3.162-.328h6.4c1.68 0 2.52 0 3.162.328a3.007 3.007 0 011.311 1.315C20 4.294 20 5.137 20 6.823v10.435c0 1.686 0 2.529-.327 3.173a3.007 3.007 0 01-1.311 1.315c-.642.328-1.482.328-3.162.328H8.8c-1.68 0-2.52 0-3.162-.328a3.007 3.007 0 01-1.311-1.315C4 19.787 4 18.944 4 17.258V6.823c0-1.686 0-2.529.327-3.173z"
          mask="url(#icon-mobile_svg__b)"
        />
        <path
          fill={primaryColor}
          d="M12 18.06c-.552 0-1-.448-1-1.003 0-.554.448-1.003 1-1.003s1 .45 1 1.003c0 .555-.448 1.004-1 1.004z"
          mask="url(#icon-mobile_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgIconMobile;
