/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconKlasor({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M4 6.736V19.78a3.005 3.005 0 003 3.01h10c1.657 0 3-1.347 3-3.01V6.736a3.005 3.005 0 00-3-3.01H7c-1.657 0-3 1.348-3 3.01z"
          fill={secondaryColor}
        />
        <path
          d="M8 12.757c0 .554.448 1.003 1 1.003h6c.552 0 1-.45 1-1.003 0-.554-.448-1.004-1-1.004H9c-.552 0-1 .45-1 1.004zm0 4.013c0 .554.448 1.004 1 1.004h4c.552 0 1-.45 1-1.004s-.448-1.003-1-1.003H9c-.552 0-1 .45-1 1.003zM10 2.723h4c.552 0 1 .45 1 1.003V4.73c0 .554-.448 1.003-1 1.003h-4c-.552 0-1-.45-1-1.003V3.726c0-.554.448-1.003 1-1.003z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconKlasor;
