/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconTwitter({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.94 19.484a9.554 9.554 0 006.827-2.812 9.619 9.619 0 002.803-6.85v-.444a7.063 7.063 0 001.68-1.754 6.964 6.964 0 01-1.943.535 3.43 3.43 0 001.5-1.882 6.654 6.654 0 01-2.145.828 3.382 3.382 0 00-4.068-.673 3.405 3.405 0 00-1.707 3.766 9.582 9.582 0 01-6.975-3.544 3.4 3.4 0 001.05 4.515 3.344 3.344 0 01-1.5-.422v.038a3.407 3.407 0 002.663 3.349 3.386 3.386 0 01-1.5.06A3.384 3.384 0 008.76 16.52a6.773 6.773 0 01-4.208 1.505 7.303 7.303 0 01-.802-.067 9.554 9.554 0 005.19 1.505"
        fill="#212739"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconTwitter;
