/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconFlash({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.848 2.932c.62-.792 1.888-.276 1.781.727L14 9.564h5.004c.834 0 1.302.965.787 1.623l-8.64 11.04c-.62.793-1.887.277-1.78-.726l.63-5.917H5.006c-.834 0-1.301-.964-.786-1.622l8.628-11.03z"
          fill={secondaryColor}
        />
        <path
          d="M10 15.584l4-6.02h5.003c.835 0 1.302.964.787 1.623l-8.639 11.04c-.62.793-1.888.276-1.78-.726l.63-5.917z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconFlash;
