import request from 'utils/request';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DATA } from './constants';
import { getDataSuccess, getDataError } from './actions';

export function* getDataSaga() {
  const reqUrl = '/';

  const options = {
    method: 'GET',
  };

  try {
    const data = yield call(request, reqUrl, options);

    yield put(getDataSuccess(data));
  } catch (err) {
    yield put(getDataError(err));
  }
}

export default function* dataSaga() {
  yield takeLatest(DATA, getDataSaga);
}
