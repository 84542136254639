/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconMegaphone({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M10 14.764H6l.774 4.658c.13.786.808 1.362 1.602 1.362.897 0 1.624-.73 1.624-1.63v-4.39z"
          fill={secondaryColor}
        />
        <path
          d="M6 8.743h5l6.238-3.661C18.904 4.103 21 5.309 21 7.247v9.019c0 1.938-2.097 3.144-3.764 2.164L11 14.763H6c-1.657 0-3-1.347-3-3.01a3.005 3.005 0 013-3.01z"
          fill={primaryColor}
        />
        <path
          d="M19.778 9.747H20c1.105 0 2 .898 2 2.006a2.003 2.003 0 01-2 2.007h-.222A1.78 1.78 0 0118 11.976v-.446a1.78 1.78 0 011.778-1.783z"
          fill={primaryColor}
        />
        <path
          d="M17.238 5.082C18.904 4.103 21 5.309 21 7.247v9.019c0 1.938-2.097 3.144-3.764 2.164L11 14.763v-6.02l6.238-3.661z"
          fill={secondaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconMegaphone;
