import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Bitmap,
  Icon3Dots,
  IconBack,
  IconCalculator,
  IconCaretDown,
  IconCaretLeft,
  IconCaretRight,
  IconCaretUp,
  IconChart,
  IconCheck,
  IconCheckbox,
  IconCheckboxCheck,
  IconCheckboxCross,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconClose,
  IconDate,
  IconDoc,
  IconDoc2,
  IconEyeOff,
  IconEyeOn,
  IconFacebook,
  IconFileDownload,
  IconHaberler,
  IconHamburgerMenu,
  IconHomeSelected,
  IconInfo,
  IconInstagram,
  IconKlasor,
  IconLinked,
  IconLocation,
  IconLock,
  IconMailnew,
  IconMegaphone,
  IconMobile,
  IconNotification,
  IconNotificationNew,
  IconPhone,
  IconProfileAgency,
  IconProfileCustomer,
  IconRadioOff,
  IconRadioOn,
  IconSearch,
  IconSettings,
  IconShieldCheck,
  IconSoru,
  IconSubmitted,
  IconTalep,
  IconTwitter,
  IconUser,
  IconWallet,
  IconYardim,
  IconUyari,
  IconAchievement,
  IconCopy,
  IconPlus,
  IconMasterCard,
  IconTroy,
  IconVisa,
  IconLogOut,
  IconSingleUser,
  IconYoutube
} from './index';
import IconMultiUser from './IconMultiUser';
import IconUserWithGuard from './IconUserWithGuard';

function Icon({ name, primaryColor = '#e2000f', secondaryColor = '#e9eaea', size = 24, ...props }) {
  if (name === 'bitmap') {
    return <Bitmap />;
  }
  if (name === '3dots') {
    return <Icon3Dots primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'back') {
    return <IconBack primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'calculator') {
    return <IconCalculator primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'caret-down') {
    return <IconCaretDown primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'caret-left') {
    return <IconCaretLeft primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'caret-right') {
    return <IconCaretRight primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'caret-up') {
    return <IconCaretUp primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'chart') {
    return <IconChart primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'check') {
    return <IconCheck primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'checkbox-check') {
    return <IconCheckboxCheck primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'checkbox-cross') {
    return <IconCheckboxCross primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'checkbox') {
    return <IconCheckbox primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'chevron-down') {
    return <IconChevronDown primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'chevron-left') {
    return <IconChevronLeft primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'chevron-right') {
    return <IconChevronRight primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'chevron-up') {
    return <IconChevronUp primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'close') {
    return <IconClose primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'date') {
    return <IconDate primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'doc') {
    return <IconDoc primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'doc-2') {
    return <IconDoc2 primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'eye-off') {
    return <IconEyeOff primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'eye-on') {
    return <IconEyeOn primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'facebook') {
    return <IconFacebook primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  
  if(name === 'youtube'){
    return <IconYoutube primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'file-download') {
    return <IconFileDownload primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'news') {
    return <IconHaberler primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'hamburger') {
    return <IconHamburgerMenu primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'home-selected') {
    return <IconHomeSelected primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'info') {
    return <IconInfo primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'instagram') {
    return <IconInstagram primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'file') {
    return <IconKlasor primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'linked') {
    return <IconLinked primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'location') {
    return <IconLocation primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'lock') {
    return <IconLock primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'mail-new') {
    return <IconMailnew primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'mega-phone') {
    return <IconMegaphone primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'mobile') {
    return <IconMobile primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'notification-new') {
    return <IconNotificationNew primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'notification') {
    return <IconNotification primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'phone') {
    return <IconPhone primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'radio-off') {
    return <IconRadioOff primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'radio-on') {
    return <IconRadioOn primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'search') {
    return <IconSearch primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'settings') {
    return <IconSettings primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'shield-check') {
    return <IconShieldCheck primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'question') {
    return <IconSoru primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'submitted') {
    return <IconSubmitted primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'talep') {
    return <IconTalep primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'twitter') {
    return <IconTwitter primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'user') {
    return <IconUser primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'warning') {
    return <IconUyari primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'wallet') {
    return <IconWallet primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'help') {
    return <IconYardim primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'plus') {
    return <IconPlus primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'profile-customer') {
    return <IconProfileCustomer primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'profile-agency') {
    return <IconProfileAgency primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'achievement') {
    return <IconAchievement primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'multi-user') {
    return <IconMultiUser primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'user-with-guard') {
    return <IconUserWithGuard primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'copy') {
    return <IconCopy primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />;
  }
  if (name === 'mastercard') {
    return <IconMasterCard  size={size} {...props} />;
  }
  if (name === 'troy') {
    return <IconTroy size={size} {...props} />;
  }
  if (name === 'visa') {
    return <IconVisa size={size} {...props} />;
  }
  if (name === 'log-out'){
    return <IconLogOut primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />
  }
  if (name === 'single-user'){
    return <IconSingleUser primaryColor={primaryColor} secondaryColor={secondaryColor} size={size} {...props} />
  }
  return <div className={name?.length > 1 ? 'icon-text__long' : 'icon-text'}>{name}</div>;
}

Icon.propTypes = {
  name: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Icon.defaultProps = {
  primaryColor: '#e2000f',
  secondaryColor: '#e9eaea',
  size: 24,
};

export default Icon;
