/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCaretDown({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.634 9.75c.488.489.488 1.283 0 1.773l-3.75 3.763c-.488.49-1.28.49-1.768 0l-3.75-3.763a1.257 1.257 0 010-1.774c.488-.49 8.78-.49 9.268 0z"
        fill={primaryColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconCaretDown;
