import {
  DATA,
  DATA_SUCCESS,
  DATA_ERROR,
  SET_MODAL_TITLE,
  SET_MODAL_CONTENT,
  SET_MODAL_FOOTER,
  SET_MODAL_OPEN,
  CLOSE_MODAL,
} from './constants';

export function getData() {
  return {
    type: DATA,
  };
}

export function getDataSuccess(data) {
  return {
    type: DATA_SUCCESS,
    data,
  };
}

export function getDataError(error) {
  return {
    type: DATA_ERROR,
    error,
  };
}

export function setModalOpen() {
  return { type: SET_MODAL_OPEN };
}

export function setModalTitle(title) {
  return { type: SET_MODAL_TITLE, title };
}

export function setModalContent(content) {
  return { type: SET_MODAL_CONTENT, content };
}

export function setModalFooter(footer) {
  return { type: SET_MODAL_FOOTER, footer };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}
