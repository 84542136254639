/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconNotification({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#DCDEDF" d="M14 20.6c0 1.11-.896 2.009-2 2.009-1.105 0-2-.9-2-2.007h4z" />
        <path
          fill="#212739"
          d="M10.995 4.548l-1.026.37A6.021 6.021 0 006 10.583v3.332c0 .736-.344 1.43-.93 1.872C3.02 17.33 4.11 20.6 6.67 20.6h10.66c2.561 0 3.65-3.27 1.602-4.815a2.347 2.347 0 01-.93-1.872v-3.331a6.02 6.02 0 00-3.97-5.665l-1.027-.37h-2.01z"
        />
        <path fill="#DCDEDF" d="M12 2.54c.552 0 1 .45 1 1.004v1.004h-2V3.544c0-.553.448-1.003 1-1.003z" />
      </g>
    </svg>
  );
}

export default SvgIconNotification;
