import axios from 'axios';

export default function request(url, options) {
  const headers = options.headers || {};
  return axios({
    method: options.method,
    url,
    headers: {
      ...headers,
    },
    auth: options.auth,
    data: options.body,
  });
}
