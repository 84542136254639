/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgVisa({ size, ...props }) {
  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 827 380"
      width={size}
      height={size}
      {...props}
    >
      <path
        fillRule="evenodd"
        fill="#1434cb"
        d="m314.73 62.81l-107.08 255.48h-69.86l-52.69-203.88c-3.2-12.56-5.98-17.16-15.71-22.45c-15.88-8.62-42.12-16.7-65.2-21.72l1.57-7.42h112.45c14.33 0 27.22 9.54 30.47 26.05l27.83 147.83l68.78-173.89h69.43v0zm158.66 259.47c-29.55-0.31-58.05-6.49-73.49-13.58l11.98-55.97c15.37 7.14 34.64 16.64 67.86 16.13c19.03-0.3 39.39-7.86 39.57-25c0.1-11.19-8.55-19.24-34.3-31.75c-25.04-12.22-58.27-32.75-57.85-69.52c0.39-49.72 46.36-84.45 111.68-84.45c25.46 0 45.92 5.55 61.82 11.33l-11.61 54.18c-29.59-13.62-55.68-12.67-65.15-11.43c-19.11 2.49-27.83 12.23-28.03 21.4c-0.64 30.12 92.88 33.84 92.6 101.26c-0.21 53.11-45.63 87.41-115.06 87.41zm279.58-42.16h-84.79l-13.78 38.16h-69.4l99.17-236.73c4.74-11.36 15.44-18.74 28.12-18.74h56.42l53.35 255.48h-61.12l-7.98-38.16zm-10.96-52.36l-20.02-95.92l-34.79 95.92h54.81zm-387.51 90.52h-66.09l54.67-255.48h66.07l-54.65 255.48z"
      />
    </svg>
  );
}

export default SvgVisa;
