/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconChevronUp({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.884 9.932l3.75 3.762c.488.49.488 1.284 0 1.774s-1.28.49-1.768 0L12 12.592l-2.866 2.876c-.488.49-1.28.49-1.768 0a1.257 1.257 0 010-1.774l3.75-3.762c.488-.49 1.28-.49 1.768 0z"
        fill={primaryColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconChevronUp;
