/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconChevronDown({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <path
        d="M9.134 9.932L12 12.808l2.866-2.876c.488-.49 1.28-.49 1.768 0s.488 1.284 0 1.773l-3.75 3.763c-.488.49-1.28.49-1.768 0l-3.75-3.763c-.488-.49-.488-1.283 0-1.773s1.28-.49 1.768 0z"
        fill={primaryColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconChevronDown;
