/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconEyeOn({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M2.269 11.31a3.108 3.108 0 000 2.529c.87 1.959 3.621 6.762 9.73 6.762s8.86-4.804 9.73-6.762a3.108 3.108 0 000-2.529c-.87-1.959-3.621-6.763-9.73-6.763s-8.86 4.804-9.73 6.763z"
          fill={secondaryColor}
        />
        <path
          d="M11.999 10.568c0-.361-.095-.7-.261-.993a3.005 3.005 0 013.261 2.999 3.005 3.005 0 01-3 3.01 3.005 3.005 0 01-2.99-3.272 2.003 2.003 0 002.989-1.745z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconEyeOn;
