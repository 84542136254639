/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconPhone({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={secondaryColor}
          d="M20.002 20.605c-1.49 1.495-7 2.007-12.5-3.512-5.5-5.518-5-11.037-3.5-12.542l.492-.493a1.746 1.746 0 012.693.268l2.02 3.04a1.762 1.762 0 01-.219 2.216L7.94 10.634c-.268.27-.358.666-.183 1.003.319.615.983 1.68 2.246 2.948 1.262 1.266 2.325 1.933 2.937 2.253.336.176.732.086 1-.183l1.048-1.052a1.746 1.746 0 012.208-.22l3.02 2.02a1.77 1.77 0 01.269 2.718l-.483.484z"
        />
        <path
          fill={primaryColor}
          d="M8.988 9.582a1.76 1.76 0 00.219-2.215l-2.02-3.04a1.746 1.746 0 00-2.694-.268l-.49.492 4 6.02.985-.99zm11.496 10.54a1.77 1.77 0 00-.268-2.72l-3.02-2.02a1.746 1.746 0 00-2.208.22l-.986.99 6 4.013.482-.484z"
        />
      </g>
    </svg>
  );
}

export default SvgIconPhone;
