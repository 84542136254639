/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconDate({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.436 5.735a4.007 4.007 0 011.748-1.754c.856-.437 1.976-.437 4.216-.437h5.2c2.24 0 3.36 0 4.216.437a4.007 4.007 0 011.748 1.754C21 6.594 21 7.718 21 9.965v5.218c0 2.248 0 3.372-.436 4.23a4.007 4.007 0 01-1.748 1.754c-.856.438-1.976.438-4.216.438H9.4c-2.24 0-3.36 0-4.216-.438a4.007 4.007 0 01-1.748-1.754C3 18.555 3 17.431 3 15.183V9.966c0-2.248 0-3.372.436-4.23z"
          fill={secondaryColor}
        />
        <path
          d="M7 8.56c0 .555.448 1.004 1 1.004h8c.552 0 1-.449 1-1.003 0-.554-.448-1.004-1-1.004H8c-.552 0-1 .45-1 1.004zm1 5.018c-.552 0-1-.45-1-1.004s.448-1.003 1-1.003 1 .45 1 1.003c0 .554-.448 1.004-1 1.004zm4 0c-.552 0-1-.45-1-1.004s.448-1.003 1-1.003 1 .45 1 1.003c0 .554-.448 1.004-1 1.004zm5-1.004c0-.554-.448-1.003-1-1.003s-1 .45-1 1.003c0 .554.448 1.004 1 1.004s1-.45 1-1.004zm-9 5.017c-.552 0-1-.449-1-1.003 0-.554.448-1.004 1-1.004s1 .45 1 1.004-.448 1.003-1 1.003zm5-1.003c0-.554-.448-1.004-1-1.004s-1 .45-1 1.004.448 1.003 1 1.003 1-.449 1-1.003zm3 1.003c-.552 0-1-.449-1-1.003 0-.554.448-1.004 1-1.004s1 .45 1 1.004-.448 1.003-1 1.003z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconDate;
