/* eslint-disable react/prop-types */
import * as React from 'react';

function IconMultiUser({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg viewBox="0 0 43.784 36.667" width={size} height={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={primaryColor} fillRule="evenodd" transform="matrix(1, 0, 0, 1, -7.333008, -3.666)">
        <path d="M16.5 25.667h11a9.167 9.167 0 0 1 9.167 9.166 5.5 5.5 0 0 1-5.5 5.5H12.833a5.5 5.5 0 0 1-5.5-5.5 9.167 9.167 0 0 1 9.167-9.166zM12.833 12.833a9.167 9.167 0 1 0 18.334 0 9.167 9.167 0 0 0-18.334 0z" />
      </g>
      <g fill={secondaryColor} fillRule="evenodd" opacity=".5" transform="matrix(1, 0, 0, 1, 15.450836, 5.489777)">
        <path d="M12.75 19.833h8.5a7.083 7.083 0 0 1 7.083 7.084 4.25 4.25 0 0 1-4.25 4.25H9.917a4.25 4.25 0 0 1-4.25-4.25 7.083 7.083 0 0 1 7.083-7.084zM9.917 9.917a7.083 7.083 0 1 0 14.166 0 7.083 7.083 0 0 0-14.166 0z" />
      </g>
    </svg>
  );
}

export default IconMultiUser;
