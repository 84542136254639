import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = state => state.global || initialState;
const selectRouter = state => state.router;

const makeSelectLoading = () => createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectError = () => createSelector(selectGlobal, globalState => globalState.error);

const makeSelectData = () => createSelector(selectGlobal, globalState => globalState.data);

const makeSelectLocation = () => createSelector(selectRouter, routerState => routerState.location);

const makeSelectModalOpen = () => createSelector(selectGlobal, globalState => globalState.isOpen);
const makeSelectModalTitle = () => createSelector(selectGlobal, globalState => globalState.modalTitle);
const makeSelectModalContent = () => createSelector(selectGlobal, globalState => globalState.modalContent);
const makeSelectModalFooter = () => createSelector(selectGlobal, globalState => globalState.modalFooter);

export {
  selectGlobal,
  makeSelectLoading,
  makeSelectError,
  makeSelectData,
  makeSelectLocation,
  makeSelectModalOpen,
  makeSelectModalTitle,
  makeSelectModalContent,
  makeSelectModalFooter,
};
