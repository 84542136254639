/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCaretLeft({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.116 7.742l-3.75 3.763a1.257 1.257 0 000 1.774l3.75 3.762c.488.49 1.28.49 1.768 0s.488-8.809 0-9.299a1.247 1.247 0 00-1.768 0z"
        fill={primaryColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconCaretLeft;
