/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIcon3Dots({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} {...props}>
      <path
        d="M12 10.385c1.105 0 2 .898 2 2.007 0 1.108-.895 2.007-2 2.007s-2-.899-2-2.007c0-1.109.895-2.007 2-2.007zm7 0c1.105 0 2 .898 2 2.007 0 1.108-.895 2.007-2 2.007s-2-.899-2-2.007c0-1.109.895-2.007 2-2.007zm-14 0c1.105 0 2 .898 2 2.007C7 13.5 6.105 14.4 5 14.4s-2-.899-2-2.007c0-1.109.895-2.007 2-2.007z"
        fill="#212739"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcon3Dots;
