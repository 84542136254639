/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgTroy({ size, ...props }) {
  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 827 380"
      width={size}
      height={size}
      {...props}
    >
      <g>
        <path
          fill="#2b3c47"
          d="m811 65.87l-129.63 243.34c-26.46 46.92-66.12 63.89-99.9 63.89l-64.74 0l8.17-48.88c3.01-13.09 9.15-18.87 18.49-18.87l36.76 0c16.47 0 32.19-13.35 35.13-29.82c0.76-4.41 0.56-8.61-0.47-12.39l-56.15-197.26l65.64 0c11.94 0 19.49 6.18 21.68 17.29l18.66 118.78l51.82-118.78c4.88-11.1 17.1-17.29 28.19-17.29h66.34z"
        />
        <path
          fill="#2b3c47"
          d="m107.41 7c19.39 0 28.76 7.73 25.34 27.17l-5.59 31.66h44.54l-9.81 55.62h-44.56l-12.14 68.87c-4.19 23.99 19.55 27.19 33.18 27.19c2.72 0 4.97-0.06 6.59-0.16l-10.9 62.02c-3.36 0.36-6.83 0.82-14.16 0.82c-33.81 0-97.78-9.06-85.21-80.42l13.77-78.33h-32.47l9.77-55.62h32.15l10.35-58.83h39.14z"
        />
        <path
          fill="#00b6bf"
          d="m558.73 171.98c0 59.75-48.43 108.21-108.23 108.21c-2.05 0-4.1-0.07-6.12-0.2l11.44-64.83c21.51-2.63 38.23-20.89 38.23-43.18c0-16.91-9.67-31.54-23.78-38.75l11.44-64.85c44.59 13.42 77.02 54.75 77.02 103.6z"
        />
        <path
          fill="#00b6bf"
          d="m419.35 275.63c-44.59-13.42-77.04-54.77-77.04-103.65c0-59.7 48.46-108.2 108.21-108.2c2.03 0 4.1 0.09 6.13 0.22l-11.42 64.84c-21.51 2.58-38.25 20.99-38.25 43.14c0 16.89 9.69 31.59 23.78 38.76l-11.42 64.89z"
        />
        <path
          fill="#2b3c47"
          d="m197.5 65.87h38.27c19.39 0 28.72 7.75 25.3 27.19l-4.19 23.52c14.26-28.94 45.19-52.82 76.92-52.82c2.77 0 5.5 0.27 8.17 0.82l-12.37 70.13c-3.7-0.86-8.44-1.29-14.22-1.29c-16.91 0-45.5 5.36-61.41 37.13c-3.8 7.83-6.71 17.35-8.73 28.83l-13.85 78.79h-71.3l37.41-212.3z"
        />
        <path
          fillRule="evenodd"
          fill="#2b3c47"
          d="m811 288.06c0 6.33-2.24 11.74-6.71 16.18c-4.42 4.45-9.81 6.73-16.18 6.73c-6.3 0-11.69-2.28-16.18-6.73c-4.46-4.44-6.71-9.85-6.71-16.18c0-6.31 2.25-11.71 6.71-16.19c4.49-4.44 9.88-6.69 16.18-6.69c6.36 0 11.75 2.24 16.18 6.69c4.47 4.49 6.71 9.88 6.71 16.19zm-9.27-13.79c-3.74-3.83-8.33-5.73-13.62-5.73c-5.28 0-9.81 1.9-13.59 5.73c-3.73 3.81-5.59 8.4-5.59 13.79c0 5.41 1.86 10.01 5.59 13.8c3.78 3.82 8.31 5.71 13.59 5.71c5.3 0 9.88-1.9 13.62-5.71c3.78-3.79 5.67-8.39 5.67-13.8c0-5.39-1.89-9.98-5.67-13.79zm-4 26.32h-4.07l-5.75-10.24h-3.78v10.24h-3.47v-25.09h8.52c2.19 0 4.07 0.75 5.6 2.17c1.52 1.44 2.33 3.23 2.33 5.34c0 3.49-1.82 5.8-5.41 6.89l6.03 10.69zm-4.2-17.58c0-1.26-0.42-2.31-1.32-3.17c-0.88-0.85-2.01-1.26-3.4-1.26h-4.67v8.8h4.67c1.39 0 2.52-0.44 3.4-1.23c0.91-0.84 1.32-1.86 1.32-3.15z"
        />
      </g>
    </svg>
  );
}

export default SvgTroy;
