/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconNotificationNew({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <g transform="translate(4 2.54)" fill="none" fillRule="evenodd">
        <path fill={secondaryColor} d="M10 18.06c0 1.11-.895 2.008-2 2.008-1.104 0-2-.899-2-2.007h4z" />
        <path
          fill="#212739"
          d="M6.996 2.007l-1.026.37A6.021 6.021 0 002 8.042v3.332c0 .736-.344 1.43-.93 1.872C-.979 14.79.11 18.06 2.671 18.06H13.33c2.562 0 3.65-3.27 1.603-4.815a2.347 2.347 0 01-.931-1.872V8.042a6.02 6.02 0 00-3.97-5.665l-1.026-.37h-2.01z"
        />
        <path fill={secondaryColor} d="M8 0c.553 0 1 .45 1 1.003v1.004H7V1.003C7 .45 7.449 0 8 0z" />
        <ellipse cx={14.001} cy={5.017} fill="#E2000F" rx={4} ry={4.014} />
      </g>
    </svg>
  );
}

export default SvgIconNotificationNew;
