/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconInfo({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={secondaryColor}
          d="M2 12.575c0 5.54 4.477 10.034 10 10.034s10-4.493 10-10.034C22 7.033 17.523 2.54 12 2.54S2 7.033 2 12.575z"
        />
        <path
          fill={primaryColor}
          d="M11 8.56c0 .555.448 1.005 1 1.005s1-.45 1-1.004-.448-1.003-1-1.003-1 .45-1 1.003zm1 3.01c-.552 0-1 .45-1 1.005v4.013c0 .554.448 1.004 1 1.004s1-.45 1-1.004v-4.013c0-.554-.448-1.004-1-1.004z"
        />
      </g>
    </svg>
  );
}

export default SvgIconInfo;
