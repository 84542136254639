/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconHamburgerMenu({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={primaryColor} fillRule="evenodd">
        <path d="M2 7.557c0 .555.448 1.004 1 1.004h18c.552 0 1-.45 1-1.004s-.448-1.003-1-1.003H3c-.552 0-1 .45-1 1.003zM2 17.591c0 .554.448 1.004 1 1.004h18c.552 0 1-.45 1-1.004s-.448-1.003-1-1.003H3c-.552 0-1 .45-1 1.003zM2 12.574c0 .554.448 1.004 1 1.004h18c.552 0 1-.45 1-1.004s-.448-1.003-1-1.003H3c-.552 0-1 .45-1 1.003z" />
      </g>
    </svg>
  );
}

export default SvgIconHamburgerMenu;
