/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconLinked({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.657 3.726H4.342A1.337 1.337 0 003 5.028v15.405a1.33 1.33 0 001.343 1.302h15.314A1.33 1.33 0 0021 20.433V5.028a1.337 1.337 0 00-1.343-1.302zM8.332 19.086h-2.64v-8.587h2.64v8.587zm-1.29-9.783a1.555 1.555 0 01-1.552-1.52 1.508 1.508 0 01.438-1.118 1.497 1.497 0 011.115-.44 1.556 1.556 0 011.444 1.554c0 .818-.63 1.497-1.445 1.554v-.03zm11.318 9.73h-2.61v-4.2c0-1 0-2.302-1.395-2.302s-1.605 1.099-1.605 2.205v4.252h-2.67v-8.489h2.49v1.129h.053a2.788 2.788 0 012.542-1.4c2.693 0 3.195 1.806 3.195 4.102v4.703z"
        fill="#212739"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconLinked;
