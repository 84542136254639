/* eslint-disable arrow-body-style */
import React, { useEffect, memo, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory, Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';
/*eslint-disable */
const Home = lazy(()=>import('containers/Home'));
const NewPasswordStep1 = lazy(()=> import('containers/NewPassword/NewPasswordStep1'));
const NewPasswordStep2 = lazy(()=> import('containers/NewPassword/NewPasswordStep2')) ;
const NewPasswordStep3 = lazy(()=> import('containers/NewPassword/NewPasswordStep3')) ;
const SecurityQuestion = lazy(()=> import('containers/SecurityQuestion'))  ;
const NewPasswordSuccessStep = lazy(()=> import('containers/NewPassword/NewPasswordSuccessStep')) ;
const Login = lazy(()=> import('containers/Login')); 
const Otp = lazy(()=> import('containers/Otp')); 
const Components = lazy(()=> import('containers/Components')); 
const AppRedirect = lazy(()=> import('containers/AppRedirect'));
const ActivePolicies = lazy(()=> import('containers/ActivePolicies/Loadable')); 
const PassivePolicies = lazy(()=> import('containers/PassivePolicies/Loadable')); 
const Payments = lazy(()=> import('containers/Payments/Loadable')); 
const Reciepts = lazy(()=> import('containers/Reciepts/Loadable')); 
const Documents = lazy(()=> import('containers/Documents/Loadable'));
const ActiveDemands = lazy(()=> import('containers/Demands/ActiveDemands')); 
const NewDemand = lazy(()=> import('containers/Demands/NewDemand')); 
const CreateDemand = lazy(()=> import('containers/Demands/CreateDemand'));
const YourSettings = lazy(()=> import('containers/YourSettings/Loadable')); 
const News = lazy(()=> import('containers/News/Loadable')); 
const FAQ = lazy(()=> import('containers/FAQ/Loadable')); 
const LiveSupport = lazy(()=> import('containers/LiveSupport/Loadable')); 
const ContactUs = lazy(()=> import('containers/ContactUs/Loadable')); 
const NotFoundPage = lazy(()=> import('containers/NotFoundPage/Loadable')); 
const PolicyDetail = lazy(()=> import('containers/PolicyDetail/Loadable')); 
const NewsDetail = lazy(()=> import('containers/NewsDetail')); 
const Profile = lazy(()=> import('containers/Profile')); 
const CellPhoneChange = lazy(()=> import('containers/CellPhoneChange')); 
const LandPhoneChange = lazy(()=> import('containers/LandPhoneChange')); 
const EmailChange = lazy(()=> import('containers/EmailChange')); 
const AddressChange = lazy(()=> import('containers/AddressChange')); 
const PaymentScreen = lazy(()=> import('containers/PaymentScreen/Loadable')) ;
const ResultPage = lazy(()=> import('containers/ResultPage')) ;
const GuestLogin = lazy(()=> import( 'containers/GuestLogin'));
const StaticPage = lazy(()=> import('containers/StaticPage')) ;
const LoadingIndicator = lazy(()=> import('components/LoadingIndicator'));
const Error = lazy(()=> import('components/Error')) ;
const Layout = lazy(()=> import('components/Layout/Layout')) ;
const ProtectedRoute = lazy(()=> import('components/ProtectedRoute')) ;
const ATModal = lazy(()=> import('components/ATModal')) ;
const ErrorPage = lazy(()=> import('containers/ErrorPage/Loadable')) ;
const PrivacyPolicy = lazy(()=> import('containers/PrivacyPolicy')) ;
const IosSupport = lazy(()=>import ('containers/IosSupport'));
const SSOLogin = lazy(()=>import ('containers/SSOLogin'));
const TemporaryAccessBlockPage = lazy(()=>import('containers/TemporaryAccessBlockPage'));
import { useLocation } from 'react-router-dom'; 
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';

import messages from './messages';
import saga from './redux/saga';
import reducer from './redux/reducer';
import { closeModal, getData } from './redux/actions';
import {
  makeSelectLocation,
  makeSelectData,
  makeSelectLoading,
  makeSelectError,
  makeSelectModalOpen,
  makeSelectModalTitle,
  makeSelectModalContent,
  makeSelectModalFooter,
} from './redux/selectors';
import { ErrorBoundary } from 'react-error-boundary';
import MobileDetect from 'mobile-detect';
import PDFPage from '../Pdf';
import ReactGA from 'react-ga';
export function App({ dispatch, loading, error, data, isOpen, modalTitle, modalContent, modalFooter }) {
  useInjectReducer({ key: 'data', reducer });
  useInjectSaga({ key: 'data', saga });
  var md = new MobileDetect(window.navigator.userAgent);
  const { formatMessage: f } = useIntl();
  const title = f(messages.title);
  const [system,setSystem]= useState(false)
  let history = useHistory();

  useEffect(() => {
    if (!data) {
      dispatch(getData());
    }
  
    if(md.os()=='iOS' && md.version('iOS') < 13){
      setSystem(true)
      history.push('/Ios-support')
    }
  }, []);
 
  const onToggle = () => {
    dispatch(closeModal());
    
  };
  const location = useLocation(); // If using React Router

  useEffect(() => {
    gtag('config', 'G-MFQG53JQRY', {
      page_path: location.pathname // If using React Router
      
    });
  }, [location]);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {loading && <LoadingIndicator />}
      {error && <Error />}

      <Helmet defaultTitle={title} titleTemplate={`${title} | %s`} />

      <Switch>
      <Route exact path="/.well-known/apple-app-site-association">
          {}
        </Route>
        <Route exact path="/app" component={AppRedirect} />
        <Route path="/GuestLogin" exact component={GuestLogin} />
        <Route path="/Login" exact component={Login} />
        <Route path="/ssoLogin" exact component={SSOLogin} />
        <Route path="/otp" exact component={Otp} />
        <Route path="/NewPassword/1" exact component={NewPasswordStep1} />
        <Route path="/NewPassword/2" exact component={NewPasswordStep2} />
        <Route path="/NewPassword/3" exact component={NewPasswordStep3} />
        <Route path="/SecurityQuestion" exact component={SecurityQuestion} />
        <Route path="/NewPassword/success" exact component={NewPasswordSuccessStep} />
        <Route path="/Viennalife" exact component={StaticPage} />
        <Route path="/access-block" exact component={TemporaryAccessBlockPage} />
        {/* <Route path="/Ios-support" exact component={IosSupport} /> */}
        <Route
        path="/Ios-support"
        exact
        render={() => (system ? <IosSupport /> : <Redirect to="/" />)}
      />
        <Layout>
          <Route path="/components" exact component={Components} />
          <ProtectedRoute path="/" exact component={Home} />
          <ProtectedRoute path="/result" exact component={ResultPage} />
          <ProtectedRoute path="/profile" exact component={Profile} />
          <ProtectedRoute path="/active-policies" exact component={ActivePolicies} />
          <ProtectedRoute path="/active-policies/:policyId" exact component={PolicyDetail} />
          <ProtectedRoute path="/passive-policies" exact component={PassivePolicies} />
          <ProtectedRoute path="/passive-policies/:policyId" exact component={PolicyDetail} />
          <ProtectedRoute path="/payments" exact component={Payments} />
          <ProtectedRoute path="/pay" exact component={PaymentScreen} />
          <ProtectedRoute path="/reciepts" exact component={Reciepts} />
          <ProtectedRoute path="/documents" exact component={Documents} />
          <ProtectedRoute path="/active-demand" exact component={ActiveDemands} />
          <ProtectedRoute path="/new-demand" exact component={NewDemand} />
          <ProtectedRoute path="/new-demand/:demandName" exact component={CreateDemand} />
          <ProtectedRoute path="/settings" exact component={YourSettings} />
          <ProtectedRoute path="/news" exact component={News} />
          <ProtectedRoute path="/news/:newsId" exact component={NewsDetail} />
          <ProtectedRoute path="/faq" exact component={FAQ} />
          <ProtectedRoute path="/live-support" exact component={LiveSupport} />
          <ProtectedRoute path="/contact-us" exact component={ContactUs} />
          <ProtectedRoute path="/cell-phone-change" exact component={CellPhoneChange} />
          <ProtectedRoute path="/land-phone-change" exact component={LandPhoneChange} />
          <ProtectedRoute path="/email-change" exact component={EmailChange} />
          <ProtectedRoute path="/address-change" exact component={AddressChange} />
          <ProtectedRoute path="/privacy-policy" exact component={PrivacyPolicy} />
          <ProtectedRoute path="/pdf" exact component={PDFPage} />
        </Layout>
        <Route exact component={NotFoundPage} />
      </Switch>
      <ATModal isOpen={isOpen} title={modalTitle} content={modalContent} footer={modalFooter} toggle={onToggle} />
    </ErrorBoundary>
  );
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  modalContent: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  modalFooter: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = createStructuredSelector({
  data: makeSelectData(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  router: makeSelectLocation(),
  isOpen: makeSelectModalOpen(),
  modalTitle: makeSelectModalTitle(),
  modalContent: makeSelectModalContent(),
  modalFooter: makeSelectModalFooter(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(compose(memo, withConnect)(App));
