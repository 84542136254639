/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconCopy({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.262 8.198c.23-.567.597-1.027 1.048-1.316.514-.328 1.186-.328 2.53-.328h8.32c1.344 0 2.016 0 2.53.328.451.289.818.75 1.048 1.316C22 8.842 22 9.684 22 11.37v6.422c0 1.686 0 2.529-.262 3.173-.23.566-.597 1.026-1.048 1.315-.514.328-1.186.328-2.53.328H9.84c-1.344 0-2.016 0-2.53-.328-.451-.289-.818-.75-1.048-1.315C6 20.32 6 19.478 6 17.792V11.37c0-1.686 0-2.528.262-3.172z"
          fill={secondaryColor}
        />
        <path
          d="M13 2.54a1 1 0 011 1v.007a1 1 0 01-1 1H3.999L4 13.58a1 1 0 01-2 0V3.541a1 1 0 011-1h10z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconCopy;
