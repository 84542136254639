/* eslint-disable react/prop-types */
import * as React from 'react';

function IconCheck({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 100 100" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#55B186"
          d="M8.333 50c0 23.012 18.655 41.667 41.667 41.667S91.667 73.012 91.667 50 73.012 8.333 50 8.333 8.333 26.988 8.333 50z"
        />
        <path
          fill="#FFF"
          d="M65.446 44.613L48.78 61.28a4.168 4.168 0 01-5.893 0l-8.333-8.334a4.166 4.166 0 015.892-5.892l5.387 5.387 13.72-13.72a4.166 4.166 0 115.893 5.892z"
        />
      </g>
    </svg>
  );
}

export default IconCheck;
