/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconLogOut({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_1_2)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0396 24C18.69 24 24.0804 18.6276 24.0804 12C24.0804 5.3724 18.69 -8.16966e-07 12.0396 -5.26268e-07C5.3904 -2.35622e-07 -8.14238e-07 5.3724 -5.24537e-07 12C-2.34835e-07 18.6276 5.3904 24 12.0396 24Z" fill={secondaryColor}/>
        <path d="M12.75 5.75C12.75 5.33579 12.4142 5 12 5C11.5858 5 11.25 5.33579 11.25 5.75L11.25 11.8971C11.25 12.3113 11.5858 12.6471 12 12.6471C12.4142 12.6471 12.75 12.3113 12.75 11.8971L12.75 5.75ZM15.9139 7.2547C15.6411 7.01593 15.2335 7.05603 14.9797 7.31484C14.6479 7.6531 14.7194 8.21197 15.0638 8.53734C15.9464 9.37119 16.5 10.5586 16.5 11.8824C16.5 14.4135 14.4825 16.4706 12 16.4706C9.5175 16.4706 7.5 14.4135 7.5 11.8824C7.5 10.5556 8.05613 9.36575 8.93841 8.52772C9.2795 8.20374 9.34851 7.64947 9.01912 7.31361C8.76597 7.0555 8.35949 7.01553 8.08739 7.25356C6.80876 8.3721 6 10.0302 6 11.8824C6 15.2624 8.685 18 12 18C15.315 18 18 15.2624 18 11.8824C18 10.0308 17.1918 8.37322 15.9139 7.2547Z" fill={primaryColor}/>
      </g>
      <defs>
      <clipPath id="clip0_1_2">
      <rect width={size} height={size} fill="white" transform="translate(0 24) rotate(-90)"/>
      </clipPath>
      </defs>
    </svg>
    
  );
}

export default SvgIconLogOut;