/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconSearch({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={secondaryColor}
          d="M14.207 13.37l6.5 6.522c.39.392.39 1.027 0 1.419a.997.997 0 01-1.414 0l-6.5-6.522a1.007 1.007 0 010-1.42.997.997 0 011.414 0z"
        />
        <path
          fill={primaryColor}
          d="M3 10.568c0 3.879 3.134 7.023 7 7.023s7-3.144 7-7.023c0-3.88-3.134-7.024-7-7.024s-7 3.145-7 7.024z"
        />
      </g>
    </svg>
  );
}

export default SvgIconSearch;
