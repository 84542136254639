/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconDoc2({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <path id="icon-doc-2_svg__a" d="M0 0h24v24.081H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M9.758 4.365H6.16c-.527 0-.981 0-1.356.03-.395.033-.789.105-1.167.298A3.005 3.005 0 002.327 6.01c-.193.38-.264.774-.296 1.17C2 7.555 2 8.012 2 8.54v7.704c0 .528 0 .985.03 1.36.033.397.104.792.297 1.171a3.005 3.005 0 001.311 1.316c.378.193.772.265 1.167.297.375.031.83.031 1.356.031H17.84c.527 0 .981 0 1.356-.03.395-.033.789-.105 1.167-.298a3.005 3.005 0 001.311-1.316c.193-.38.264-.774.296-1.17.031-.376.031-.833.031-1.361v-5.697c0-.529 0-.985-.03-1.36-.033-.398-.104-.792-.297-1.172A3.005 3.005 0 0020.362 6.7c-.378-.194-.772-.265-1.167-.298-.375-.03-.83-.03-1.356-.03h-4.425l-.829-.832c-.287-.289-.54-.544-.846-.731a2.994 2.994 0 00-.867-.36c-.349-.085-.707-.084-1.114-.084z"
          fill={secondaryColor}
        />
        <path
          d="M12.462 9.693l.79 1.904a.5.5 0 00.418.307l1.874.163c.43.038.614.566.302.864l-1.424 1.358a.503.503 0 00-.142.48l.48 2.021a.5.5 0 01-.747.545l-1.752-1.074a.499.499 0 00-.521 0l-1.753 1.074a.5.5 0 01-.747-.545l.48-2.022a.503.503 0 00-.14-.48L8.16 12.93a.502.502 0 01.302-.863l1.869-.163a.5.5 0 00.418-.307l.79-1.904a.5.5 0 01.923 0z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconDoc2;
