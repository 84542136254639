/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconAchievement({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill={primaryColor} opacity=".5">
          <path d="M7.784 19.873a.406.406 0 0 0-.107.019l-1.82.558a.418.418 0 0 1-.51-.56L8.56 12.14l2.118.878 2.35.973-3.21 7.752a.418.418 0 0 1-.756.036l-.892-1.683a.418.418 0 0 0-.332-.22z" />
          <path d="m15.168 12.139 3.21 7.752a.418.418 0 0 1-.508.56l-1.82-.56a.418.418 0 0 0-.384.074v-.002a.479.479 0 0 0-.078.083l-.03.05-.893 1.682a.418.418 0 0 1-.755-.036l-3.211-7.752 2.412-1 2.057-.851z" />
        </g>
        <path
          d="M12.67 2.281c.335.266.781.35 1.19.223a1.297 1.297 0 0 1 1.504.582c.217.37.603.609 1.03.638a1.297 1.297 0 0 1 1.192 1.086c.069.424.342.786.73.967.546.256.844.854.719 1.444-.089.42.035.855.332 1.165.416.436.478 1.101.148 1.606-.234.359-.276.81-.111 1.206.23.557.048 1.2-.442 1.551-.348.25-.55.657-.54 1.085a1.297 1.297 0 0 1-.972 1.287c-.415.107-.75.413-.895.816a1.297 1.297 0 0 1-1.371.85 1.297 1.297 0 0 0-1.13.437 1.297 1.297 0 0 1-1.585.296c-.38-.2-.833-.2-1.212 0a1.297 1.297 0 0 1-1.585-.296 1.297 1.297 0 0 0-1.13-.438 1.297 1.297 0 0 1-1.37-.849 1.297 1.297 0 0 0-.896-.816 1.297 1.297 0 0 1-.972-1.287 1.297 1.297 0 0 0-.54-1.085 1.297 1.297 0 0 1-.441-1.55 1.297 1.297 0 0 0-.112-1.207 1.297 1.297 0 0 1 .149-1.606c.296-.31.42-.746.331-1.165a1.297 1.297 0 0 1 .72-1.444c.387-.181.66-.543.73-.967a1.297 1.297 0 0 1 1.191-1.086c.428-.03.813-.268 1.03-.638.305-.52.928-.761 1.504-.582.41.127.855.043 1.19-.223a1.297 1.297 0 0 1 1.613 0z"
          fill="#FFB100"
        />
        <path
          d="m11.096 15.507-.156-.023a5.712 5.712 0 0 1-.351-.068l.295.059a5.695 5.695 0 0 1-.263-.052l-.032-.007.003-.001a5.722 5.722 0 0 1 0-11.161l.027-.006c.23-.051.466-.089.706-.11l-.125.012.03-.003.095-.01.006.002.206-.018.326-.009a5.723 5.723 0 1 1-.634 11.411l-.014-.001-.119-.015z"
          fill="#FFB100"
          opacity=".5"
        />
        <path
          d="M11.598 5.98a.282.282 0 0 1 .53 0l.817 2.237c.04.108.14.181.255.186l1.671.06.709.025c.263.01.37.342.164.504l-.367.287-1.508 1.181a.282.282 0 0 0-.098.3l.654 2.29a.282.282 0 0 1-.43.312l.057.029-.056-.03-.115-.077-1.86-1.252a.283.283 0 0 0-.315 0l-1.976 1.33a.282.282 0 0 1-.429-.313l.654-2.289a.282.282 0 0 0-.097-.3L7.983 8.992a.282.282 0 0 1 .164-.504l2.38-.086a.282.282 0 0 0 .254-.185z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default SvgIconAchievement;
