/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconSettings({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.5 2.358h1c.828 0 1.5.674 1.5 1.505v.366c0 .229.155.427.373.495.47.146.923.335 1.353.563a.52.52 0 00.612-.086l.258-.259a1.496 1.496 0 012.122 0l.707.71c.585.587.585 1.54 0 2.128l-.258.26a.525.525 0 00-.086.614c.227.43.415.884.561 1.357a.522.522 0 00.494.374h.364c.828 0 1.5.674 1.5 1.505v1.004c0 .83-.672 1.505-1.5 1.505h-.364a.522.522 0 00-.494.374 7.992 7.992 0 01-.56 1.357.525.525 0 00.085.615l.258.259c.585.587.585 1.54 0 2.128l-.707.71a1.496 1.496 0 01-2.122 0l-.258-.26a.52.52 0 00-.612-.085c-.43.227-.882.416-1.353.563a.523.523 0 00-.373.495v.366c0 .83-.672 1.505-1.5 1.505h-1c-.828 0-1.5-.674-1.5-1.505v-.366a.523.523 0 00-.373-.495 7.935 7.935 0 01-1.353-.563.52.52 0 00-.612.086l-.258.259a1.496 1.496 0 01-2.122 0l-.707-.71a1.509 1.509 0 010-2.128l.258-.26a.525.525 0 00.086-.614 7.992 7.992 0 01-.561-1.357.522.522 0 00-.494-.374H3.5c-.828 0-1.5-.674-1.5-1.505V11.89c0-.831.672-1.505 1.5-1.505h.364a.522.522 0 00.494-.374c.146-.473.334-.926.56-1.357a.525.525 0 00-.085-.615l-.258-.259a1.509 1.509 0 010-2.128l.707-.71a1.496 1.496 0 012.122 0l.258.26a.52.52 0 00.612.085c.43-.228.882-.417 1.353-.563A.523.523 0 0010 4.229v-.366c0-.831.672-1.505 1.5-1.505z"
          fill={primaryColor}
        />
        <path
          d="M12 15.402c-1.657 0-3-1.348-3-3.01a3.005 3.005 0 013-3.01c1.657 0 3 1.347 3 3.01a3.005 3.005 0 01-3 3.01z"
          fill={secondaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconSettings;
