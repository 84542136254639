/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconYardim({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M20.478 3.361a2.53 2.53 0 012.526 2.535v7.605l.017 4.698a.5.5 0 01-.854.355l-2.51-2.518H16v-3.654a3 3 0 00-2.824-2.995L13 9.382H6.162V5.895a2.53 2.53 0 012.526-2.535h11.79z"
          fill={primaryColor}
        />
        <path
          d="M11.985 11.389c1.105 0 2 .898 2 2.006v5.017a2.003 2.003 0 01-2 2.007H4.101l-1.243 1.194a.5.5 0 01-.847-.36v-2.515a2.028 2.028 0 01-.026-.326v-5.017c0-1.108.896-2.006 2-2.006h8zm-.485 5.016h-2a.5.5 0 000 1.003h2a.5.5 0 000-1.003zm0-2.006h-5a.5.5 0 000 1.003h5a.5.5 0 000-1.003z"
          fill={secondaryColor}
        />
      </g>
    </svg>
  );
}

export default SvgIconYardim;
