/* eslint-disable react/prop-types */
import * as React from 'react';

function SvgIconHaberler({ primaryColor, secondaryColor, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M22 17.409V5.369c-3 0-6 .668-9 2.006v12.04c2.333-1.06 5.333-1.73 9-2.006z" fill={primaryColor} />
        <path d="M2 17.409V5.369c3 0 6 .668 9 2.006v12.04c-2.333-1.06-5.333-1.73-9-2.006z" fill={secondaryColor} />
      </g>
    </svg>
  );
}

export default SvgIconHaberler;
